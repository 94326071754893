import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FaPrint } from "react-icons/fa6";
import { styled } from "@mui/material/styles";
import { Box, TextField } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TablePagination from "@mui/material/TablePagination";
import { Link, useNavigate, useParams } from "react-router-dom";
import moment from "moment/moment";
import { DatePicker, Select, Button, Flex, Modal, message } from "antd";
import { FaRegCheckCircle, FaBell, FaReply } from "react-icons/fa";
import { LuXCircle } from "react-icons/lu";
import { MessageBox, ChatItem, MessageList, Input } from "react-chat-elements";
import axios from "axios";
import {
  getStudentnotifications,
  updateNotifications,
} from "../../../redux/Notifications/Notificationslice";
import "react-chat-elements/dist/main.css";

const { RangePicker } = DatePicker;

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    // backgroundColor: theme.palette.common.black,
    backgroundColor: "#006fa9",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&td, &th": {
    border: 0,
  },
}));

const NotificationsByStudentId = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();

  const messageListReferance = React.createRef();
  const [selectedservices, setselectedservices] = useState("");
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [filter_data, setfilter_data] = useState([]);
  const [start_date, setstart_date] = useState("");
  const [end_date, setend_date] = useState("");
  const [search, setSearch] = useState("");

  const [content, setContent] = useState("");
  const [contentError, setContentError] = useState("");

  useEffect(() => {
    const get_notifications = async () => {
      const url = `${process.env.REACT_APP_BASE_URL}/api/v1/notification/notifications-by-stydentid/${params.id}`;
      const fetch_notifications = await axios.get(url);
      if (fetch_notifications.data.success === true) {
        const data = fetch_notifications.data.notifications;
        const sortdata = data.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        );
        setfilter_data([...sortdata]);
      }
    };
    get_notifications();
  }, [params.id]);

  useEffect(() => {
    let filter_trans = [...filter_data];
    if (search !== "") {
      filter_trans = filter_trans.filter((transaction) => {
        return (
          transaction.StudentName.toLowerCase().match(search.toLowerCase()) ||
          transaction.massage.toLowerCase().match(search.toLowerCase())
        );
      });
    }
    if (end_date !== "" && start_date !== "") {
      filter_trans = filter_trans.filter(
        (transaction) =>
          moment(transaction.createdAt).format("YYYY-MM-DD") >= start_date &&
          moment(transaction.createdAt).format("YYYY-MM-DD") <= end_date
      );
    }
    setfilter_data([...filter_trans]);
  }, [search, end_date, start_date]);

  const onChange = (date, dateString) => {
    setstart_date(dateString[0]);
    setend_date(dateString[1]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const studentupdate = async (e) => {
    e.preventDefault();
    if (content === "") {
      setContentError("please enter Massage");
    } else {
      setContentError("");
    }
    if (content !== "") {
      const url = `${process.env.REACT_APP_BASE_URL}/api/v1/notification/update-single-massage`;
      const formdata = {
        Id: selectedservices._id,
        NotificationStatus: "Read",
        massageChat: {
          text: content.trim(),
          Role: "Admin",
          position: "right",
        },
      };
      const config = {
        Headers: {
          "content-type": "application/json",
        },
      };
      const posrReq = await axios.put(url, formdata, config);
      if (posrReq.data.success === true) {
        const reqRespo = posrReq.data.notification;
        await dispatch(updateNotifications(posrReq.data.notification));
        setselectedservices(reqRespo);
        let updaterequest = filter_data;
        updaterequest = updaterequest.map((data) => {
          if (data._id === reqRespo._id) {
            data = reqRespo;
          }
          return data;
        });
        setfilter_data(updaterequest);
        setContent("");
        setContentError("");
      }
    } else {
    }
  };

  return (
    <>
      <Modal
        open={modal}
        title=" "
        onOk={(e) => studentupdate(e)}
        onCancel={() => {
          setModal(false);
          setselectedservices("");
        }}
        okText="Send"
        footer={(_, { OkBtn }) => (
          <>
            <OkBtn />
          </>
        )}
      >
        <div className="Model_container">
          <p>
            {selectedservices.StudentName} - {selectedservices.massage}
          </p>

          <div className="dn_input_box mt-3">
            {selectedservices !== "" && (
              <MessageList
                referance={messageListReferance}
                className="message-list"
                lockable={true}
                toBottomHeight={"100%"}
                dataSource={selectedservices.massageChat}
              />
            )}
          </div>
          <div className="dn_input_box mt-3">
            <TextField
              label="Massage"
              className="input_style"
              error={contentError !== "" ? true : false}
              helperText={contentError}
              value={content}
              onChange={(e) => setContent(e.target.value)}
              autocomplete="off"
              id="outlined-multiline-static"
              multiline
              rows={3}
            />
          </div>
        </div>
      </Modal>

      <div className="main_fragnent_container p-2">
        <div className="profile_container_header">
          <div className="profile_co_header_left">
            <span>{filter_data[0]?.StudentName}'s Notifications</span>
            <span
              style={{
                visibility: "hidden",
              }}
            >
              S
            </span>
          </div>
          <div className="profile_co_header_right"></div>
        </div>
        <div className="transaction_header">
          <Input
            placeholder="search"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            style={{
              width: "50vh",
              border: "1px solid gray",
            }}
          />
          <TablePagination
            component="div"
            count={0}
            page={0}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            rowsPerPageOptions={[10, 25, 100]}
            labelRowsPerPage="Rows:"
            labelDisplayedRows={() => null}
            SelectProps={{
              inputProps: { "aria-label": "rows" },
              native: true,
            }}
            ActionsComponent={() => null}
          />
        </div>
        <div className="letter_content">
          <Paper sx={{ width: "100%", overflow: "hidden" }}>
            <TableContainer component={Paper} className="customScrollbar">
              <Table
                sx={{ minWidth: 700, border: "none" }}
                aria-label="customized table"
              >
                <TableHead>
                  <TableRow>
                    <StyledTableCell sx={{ border: "none" }}>
                      No
                    </StyledTableCell>
                    <StyledTableCell align="left" sx={{ border: "none" }}>
                      Name
                    </StyledTableCell>
                    <StyledTableCell align="left" sx={{ border: "none" }}>
                      Massage
                    </StyledTableCell>
                    <StyledTableCell align="left" sx={{ border: "none" }}>
                      Status
                    </StyledTableCell>
                    <StyledTableCell align="left" sx={{ border: "none" }}>
                      Date
                    </StyledTableCell>
                    <StyledTableCell align="center" sx={{ border: "none" }}>
                      Action
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filter_data
                    .slice(page * rowsPerPage, (page + 1) * rowsPerPage)
                    .map((serevice, index) => (
                      <StyledTableRow key={index}>
                        <StyledTableCell
                          component="th"
                          scope="row"
                          sx={{ border: "none" }}
                          width={50}
                        >
                          {index + 1}
                        </StyledTableCell>
                        <StyledTableCell
                          width={250}
                          align="left"
                          sx={{ border: "none" }}
                        >
                          {serevice.StudentName.length > 40 ? (
                            <>{serevice.StudentName.slice(0, 40)}...</>
                          ) : (
                            <>{serevice.StudentName.slice(0, 40)}</>
                          )}
                        </StyledTableCell>
                        <StyledTableCell
                          width={250}
                          align="left"
                          sx={{ border: "none" }}
                        >
                          {serevice.massage.length > 40 ? (
                            <>{serevice.massage.slice(0, 40)}...</>
                          ) : (
                            <>{serevice.massage.slice(0, 40)}</>
                          )}
                        </StyledTableCell>
                        <StyledTableCell
                          width={100}
                          align="left"
                          sx={{ border: "none" }}
                        >
                          {serevice.NotificationStatus}
                        </StyledTableCell>
                        <StyledTableCell
                          width={150}
                          align="left"
                          sx={{ border: "none" }}
                        >
                          {moment(serevice.createdAt).format(
                            "DD-MM-YYYY hh-mm"
                          )}
                        </StyledTableCell>

                        <StyledTableCell
                          align="center"
                          width={50}
                          sx={{ border: "none" }}
                        >
                          <Flex gap="small" wrap>
                            <div
                              className="approve_box"
                              onClick={() => {
                                setselectedservices(serevice);
                                toggle();
                              }}
                            >
                              <FaReply />
                            </div>
                          </Flex>
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>

            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={filter_data.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </div>
      </div>
    </>
  );
};

export default NotificationsByStudentId;
