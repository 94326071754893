import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { json } from "react-router-dom";
import axios from "axios";
import { act } from "react-dom/test-utils";

const Baseurl = process.env.REACT_APP_BASE_URL
const initialState = {
    totalNotifications: [],
    totalUniversityNotifications: [],
    notificationsLoading: true,
    totalStidentNotifications: [],
    studentNotificationLoading: false
};

export const notificationsPost = createAsyncThunk(
    "notifications/notificationsPost",
    async (formData, thunkAPI) => {
        try {
            const config = {
                Headers: {
                    "content-type": "application/json",
                },
            };
            const url = `${Baseurl}/api/v1/notification/new`;
            const resp = await axios.post(url, formData, config);

            return resp.data;
        } catch (error) {
            return thunkAPI.rejectWithValue("notifications not able to upload");
        }
    }
);

export const getAllnotifications = createAsyncThunk(
    "notifications/getAllnotifications",
    async (number, thunkAPI) => {
        try {
            const url = `${Baseurl}/api/v1/notification/all`;
            const resp = await axios.get(url);
            return resp.data;
        } catch (error) {
            return error;
        }
    }
);
export const getStudentnotifications = createAsyncThunk(
    "notifications/getStudentnotifications",
    async (number, thunkAPI) => {
        try {
            const url = `${Baseurl}/api/v1/notification/notifications-by-stydent`;
            const resp = await axios.get(url);
            return resp.data;
        } catch (error) {
            return error;
        }
    }
);
export const getUniversitynotifications = createAsyncThunk(
    "notifications/getUniversitynotifications",
    async (number, thunkAPI) => {
        try {
            const url = `${Baseurl}/api/v1/notification/notifications-by-university`;
            const resp = await axios.get(url);
            return resp.data;
        } catch (error) {
            return error;
        }
    }
);
export const updateNotifications = createAsyncThunk(
    "notifications/updateNotifications",
    async (formData, thunkAPI) => {
        try {
            const config = {
                Headers: {
                    "content-type": "application/json",
                },
            };
            const url = `${Baseurl}/api/v1/notification/updatenotifications/${formData._id}`;
            const resp = await axios.put(url, formData, config);
            return resp.data;
        } catch (error) {
            return thunkAPI.rejectWithValue("auth not able to update");
        }
    }
);
export const allNotifications_fromStudent = createAsyncThunk(
    "notifications/allNotifications_fromStudent",
    async (formData, thunkAPI) => {
        try {
            const config = {
                Headers: {
                    "content-type": "application/json",
                },
            };
            const url = `${Baseurl}/api/v1/notification/notifications-by-stydent`;
            const resp = await axios.get(url, formData, config);
            return resp.data.notifications;
        } catch (error) {
            return thunkAPI.rejectWithValue("auth not able to update");
        }
    }
);


const notificationsSlice = createSlice({
    name: "notifications",
    initialState,
    reducers: {
        updateNotification(state, action) {
            state.totalNotifications = action.payload;
            state.totalUniversityNotifications = state.totalNotifications.filter((data) => data.Role === "University")
            state.totalStidentNotifications = state.totalNotifications.filter((data) => data.Role === "Student")
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAllnotifications.pending, (state) => {
                state.notificationsLoading = true;
            })
            .addCase(getAllnotifications.fulfilled, (state, action) => {
                if (action.payload.success) {
                    state.totalNotifications = action.payload.notifications
                    state.totalUniversityNotifications = state.totalNotifications.filter((data) => data.Role === "University")
                    state.totalStidentNotifications = state.totalNotifications.filter((data) => data.Role === "Student")
                }
                state.notificationsLoading = false;
            })
            .addCase(getAllnotifications.rejected, (state) => {
                state.notificationsLoading = true;
            })
            .addCase(notificationsPost.pending, (state) => {
                state.notificationsLoading = true;
            })
            .addCase(notificationsPost.fulfilled, (state, action) => {
                if (action.payload.success) {
                    state.totalNotifications = [action.payload.notifications, ...state.totalNotifications];
                    state.totalUniversityNotifications = state.totalNotifications.filter((data) => data.Role === "University")
                    state.totalStidentNotifications = state.totalNotifications.filter((data) => data.Role === "Student")
                }
                state.notificationsLoading = false;
            })
            .addCase(notificationsPost.rejected, (state) => {
                state.notificationsLoading = true;
            })

            .addCase(updateNotifications.pending, (state) => {
                state.notificationsLoading = true;
            })
            .addCase(updateNotifications.fulfilled, (state, action) => {
                if (action.payload.success) {
                    state.totalNotifications = state.totalNotifications.filter(
                        (admin) => admin._id !== action.payload.notifications._id
                    );
                    state.totalNotifications = [action.payload.notifications, ...state.totalNotifications];
                    state.totalUniversityNotifications = state.totalNotifications.filter((data) => data.Role === "University")
                    state.totalStidentNotifications = state.totalNotifications.filter((data) => data.Role === "Student")
                }
                state.notificationsLoading = false;
            })
            .addCase(updateNotifications.rejected, (state) => {
                state.notificationsLoading = true;
            })
    },
});


export const {
    updateNotification
} = notificationsSlice.actions;
export default notificationsSlice.reducer;