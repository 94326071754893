import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import MenuItem from "@mui/material/MenuItem";
import { TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { PiPaperPlaneRight } from "react-icons/pi";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import CircularProgress from "@mui/material/CircularProgress";
import { Typography, message } from "antd";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import {
  blogPost,
  blogSliderUpload,
  blogThumbnailUpload,
  resestBlogImage,
  valiadteSlugurl,
} from "../../../redux/Blogs/BlogSlice";

const Add_Blogs = () => {
  const {
    totalBlogs,
    isblogSliderLoading,
    isblogIconLoading,
    isblogThumbnailLoading,
    blogSlider,
    blogThumbnail,
    blogIcon,
  } = useSelector((store) => store.blog);

  const navigate = useNavigate();
  const aadhar_frontRef = useRef(null);
  const aadhar_backRef = useRef(null);
  // const editor = useRef(null);
  const [content, setContent] = useState("");

  const [value, setValue] = useState("");

  const [title, setTitle] = useState("");
  const [slugUrl, setSlugUrl] = useState("");
  const [checkSlugUrl, setCheckSlugUrl] = useState("");
  const [slugUrlError, setSlugUrlError] = useState("");
  const [titleError, seTitleError] = useState("");
  const [subTitle, setSubTitle] = useState("");
  const [category, setcategory] = useState("");
  const [categoryError, setcategoryError] = useState("");
  const [contentError, setContentError] = useState("");
  const [blogVideoYoutubeLink, setblogVideoYoutubeLink] = useState("");
  const [blogVideoTwitterLink, setblogVideoTwitterLink] = useState("");
  const [blogVideoFacebooxLink, setblogVideoFacebooxLink] = useState("");
  const [blogVideoInstagramLink, setblogVideoInstagramLink] = useState("");
  const [blogVideoLinkedInLink, setblogVideoLinkedInLink] = useState("");
  const [email, setEmail] = useState("");
  const [Cnfemail, setCnfEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [mobile, setMobile] = useState("");
  const [Name, setName] = useState("");
  const [referance, setreferance] = useState("");
  const [mobileError, setMobileError] = useState("");
  const [thumbnailError, setthumbnailError] = useState("");
  const [SliderError, setSliderError] = useState("");
  const fileTypes = ["JPG", "PNG"];
  const [file, setFile] = useState(null);

  const [BlogMorearray, setBlogMorearray] = useState([
    {
      blogContent: "",
      thumbnail: "",
    },
  ]);

  const [btnPress, setbtnPress] = useState(false);
  const [btnLoading, setbtnLoading] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();

  const categoryArray = [
    { name: "General" },
    { name: "Latest F1 News" },
    { name: "STEM Programs" },
    { name: "Application Information" },
    { name: "Student Loans" },
    { name: "F1/F2 Visa" },
    { name: "I-20 Transfer" },
    { name: "Student/CPT Employment" },
    { name: "Health Insurance" },
    { name: "SEVIS Related" },
    { name: "Miscellaneous" },
  ];

  useEffect(() => {
    if (btnPress === true) {
      if (checkSlugUrl === "") {
        setSlugUrlError("Required");
      } else {
        setSlugUrlError("");
      }
      if (category === "") {
        setcategoryError("Required");
      } else {
        setcategoryError("");
      }
      if (blogSlider.length === 0) {
        setSliderError("Required");
      } else {
        setSliderError("");
      }
    }
  }, [btnPress, checkSlugUrl, blogSlider, category]);

  const aadharFrontClick = () => {
    aadhar_frontRef.current.click();
  };
  const aadhar_back_click = () => {
    aadhar_backRef.current.click();
  };

  const handleNewRowQuestion = () => {
    const lastIndex = BlogMorearray.length - 1;

    if (BlogMorearray[lastIndex].blogContent === "") {
    } else {
      setBlogMorearray([
        ...BlogMorearray,
        {
          blogContent: "",
          thumbnail: "",
        },
      ]);
    }
  };

  const removeRowClickQuestion = (e, crrentindex) => {
    const optionList = [...BlogMorearray];
    const filterdata = optionList.filter(
      (data, index) => index !== crrentindex
    );
    setBlogMorearray(filterdata);
  };

  const dispatch = useDispatch();

  const handleTitle = async (e) => {
    const value = e.target.value.trim();
    setSlugUrl(value);
    setTitle(value);
    let slug = value
      .trim()
      .toLowerCase()
      .replace(" ", "-")
      .replace(/[.*+&?^ $@#%^!'/{}()|[\]\\]/g, "-")
      .replace("---", "-")
      .replace("----", "-")
      .replace("--", "-");
    setSlugUrl(slug);
    if (slug !== "") {
      let response = await dispatch(valiadteSlugurl(slug));
      if (response.payload.success) {
        setSlugUrlError("name already exist");
      } else {
        setCheckSlugUrl(slug);
        setTitle(value);
        setSlugUrlError("");
      }
    }
  };

  const emailChange = (e) => {
    const value = e;
    const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    setEmail(value);
    setEmailError("");
    if (value.length > 0) {
      if (value.match(mailformat)) {
        setCnfEmail(value);
      } else {
        setEmailError("please enter correct Email format");
      }
    } else {
      setEmailError("please enter correct Email format");
    }
  };

  const sliderChange = (e) => {
    const files = Array.from(e.target.files);
    files.forEach((file) => {
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.readyState === 2) {
          dispatch(blogSliderUpload({ slider: reader.result }));
        }
      };
      reader.readAsDataURL(file);
    });
  };

  const handlesubmit = async (e) => {
    e.preventDefault();
    setbtnPress(true);
    setbtnLoading(true);
    if (checkSlugUrl === "") {
      setSlugUrlError("Required");
    } else {
      setSlugUrlError("");
    }
    if (blogSlider.length === 0) {
      setSliderError("Required");
    } else {
      setSliderError("");
    }
    if (category === "") {
      setcategoryError("Required");
    } else {
      setcategoryError("");
    }

    if (
      blogSlider.length > 0 &&
      title !== "" &&
      checkSlugUrl !== "" &&
      category !== ""
    ) {
      const formData = {
        slider: blogSlider,
        blogTitle: title,
        slugUrl: checkSlugUrl,
        blogSubTitle: subTitle,
        blogarray: BlogMorearray,
        email: Cnfemail,
        author: Name,
        referance: referance,
        category: category,
      };
      const blog = await dispatch(blogPost(formData));
      if (blog.payload.success) {
        messageApi.open({
          type: "success",
          content: "Blog created successfully",
        });
        await dispatch(resestBlogImage());
        setBlogMorearray([
          {
            blogContent: "",
            thumbnail: "",
          },
        ]);
        setbtnPress(false);
        setName("");
        setCnfEmail("");
        setEmail("");
        setreferance("");
        setTitle("");
        setCheckSlugUrl("");
        setSlugUrl("");
        setSlugUrlError("");
        setSubTitle("");
        setblogVideoYoutubeLink("");
        setblogVideoTwitterLink("");
        setblogVideoFacebooxLink("");
        setblogVideoInstagramLink("");
        setblogVideoLinkedInLink("");
        setSliderError("");
        setcategory("");
        setcategoryError("");
        setbtnLoading(false);
      } else {
        setbtnPress(false);
        setbtnLoading(false);
      }
    } else {
      setbtnPress(true);
      setbtnLoading(false);
    }
  };

  const imageRemoveClick = (e) => {
    dispatch(blogThumbnailUpload({ thumbnail: "" }));
  };

  const handleCheckQuestion = async (index, e, selected) => {
    let temp = [...BlogMorearray];

    if (selected === "blogContent") {
      temp[index].blogContent = e;
    } else if (selected === "thumbnail") {
      const files = Array.from(e.target.files);
      files.forEach((file) => {
        const reader = new FileReader();
        reader.onload = async () => {
          if (reader.readyState === 2) {
            const getImage = await dispatch(
              blogThumbnailUpload({ thumbnail: reader.result })
            );
            if (getImage.payload.success) {
              const image = getImage.payload.thumbnails;
              temp[index][selected] = image;
            }
          }
        };
        reader.readAsDataURL(file);
      });
    } else {
      temp[index][selected] = e.target.value;
    }

    setBlogMorearray(temp);
  };

  const LoadingButton = styled(Button)(({ theme }) => ({
    position: "relative",
  }));

  const LoadingIndicator = styled(CircularProgress)(({ theme }) => ({
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  }));

  return (
    <>
      {contextHolder}
      <div className="main_fragnent_container p-2">
        <div className="profile_container_header">
          <div className="profile_co_header_left">
            <span>Add Blog Writing</span>
            <span
              style={{
                visibility: "hidden",
              }}
            >
              Student
            </span>
          </div>
          <div className="profile_co_header_right"></div>
        </div>
        <div className="main_recha_container">
          <div className="inputs_container">
            <div className="dn_input_box">
              <div className="input_style ">
                <h6>Slider</h6>
                <input
                  type="file"
                  multiple="multiple"
                  id="img"
                  name="img"
                  accept="image/*"
                  onChange={(e) => sliderChange(e)}
                  className={SliderError !== "" ? "error_class" : ""}
                />
                <div>
                  {isblogSliderLoading ? (
                    <></>
                  ) : (
                    <>
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          flexWrap: "wrap",
                        }}
                      >
                        {blogSlider.map((image, index) => (
                          <div
                            style={{
                              position: "relative",
                              height: "100px",
                              width: "150px",
                              margin: "5px",
                            }}
                            key={index}
                          >
                            <img
                              src={image}
                              height="100px"
                              width="100px"
                              alt="img"
                            />
                            {/* <span
                              style={{
                                position: "absolute",
                                top: 0,
                                right: 3,
                              }}
                              onClick={(e) => imageRemoveClick(e)}
                            >
                              <h6
                                style={{
                                  fontWeight: "bold",
                                  color: "red",
                                  cursor: "pointer",
                                  fontSize: "20px",
                                }}
                              >
                                X
                              </h6>
                            </span> */}
                          </div>
                        ))}
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="dn_input_box">
              <TextField
                id="select"
                label="Category"
                select
                className="input_style"
                error={categoryError !== "" ? true : false}
                helperText={categoryError}
                value={category}
                onChange={(e) => {
                  setcategory(e.target.value);
                }}
                size="small"
              >
                {categoryArray &&
                  categoryArray.map((data, index) => (
                    <MenuItem key={index} value={data.name}>
                      {data.name}
                    </MenuItem>
                  ))}
              </TextField>
            </div>
            <div className="dn_input_box">
              <TextField
                label="title"
                type="text"
                id="text"
                className="input_style"
                error={slugUrlError !== "" ? true : false}
                helperText={slugUrlError}
                value={title}
                onChange={(e) => handleTitle(e)}
                autocomplete="off"
                size="small"
              />
            </div>
            <div className="dn_input_box">
              <TextField
                label="subTitle"
                type="text"
                id="text"
                className="input_style"
                value={subTitle}
                onChange={(e) => setSubTitle(e.target.value)}
                autocomplete="off"
                size="small"
              />
            </div>
            {BlogMorearray.map((blog, index) => (
              <>
                <div className="dn_input_box">
                  <div className="input_style">
                    <h6>Thumbnail</h6>
                    <input
                      type="file"
                      id="img"
                      name="img"
                      accept="image/*"
                      onChange={(e) =>
                        handleCheckQuestion(index, e, "thumbnail")
                      }
                    />
                    <div>
                      {!blog?.thumbnail ? (
                        <>
                          <div
                            style={{
                              width: "100%",
                              display: "flex",
                              flexWrap: "wrap",
                            }}
                          >
                            <div
                              style={{
                                position: "relative",
                                width: "150px",
                                margin: "5px",
                              }}
                            >
                              <img
                                src={blog?.thumbnail}
                                width="100%"
                                alt="img"
                              />
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <div
                            style={{
                              width: "100%",
                              display: "flex",
                              flexWrap: "wrap",
                            }}
                          >
                            <div
                              style={{
                                position: "relative",
                                width: "150px",
                                margin: "5px",
                              }}
                            >
                              <img
                                src={blog?.thumbnail}
                                width="100%"
                                alt="img"
                              />
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
                <div className="dn_input_box">
                  <div className="input_style "></div>
                </div>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                  }}
                >
                  <div
                    style={{
                      width: "90%",
                    }}
                  >
                    <h6>Content</h6>
                    <ReactQuill
                      theme="snow"
                      value={blog?.blogContent}
                      onChange={(e) =>
                        handleCheckQuestion(index, e, "blogContent")
                      }
                    />
                  </div>
                  {BlogMorearray.length !== 1 && (
                    <div
                      className="space-y-1"
                      style={{
                        width: "6%",
                        marginLeft: "2%",
                      }}
                    >
                      <LoadingButton
                        variant="contained"
                        color="primary"
                        onClick={(e) => removeRowClickQuestion(e, index)}
                      >
                        Remove
                      </LoadingButton>
                    </div>
                  )}
                </div>
              </>
            ))}
            <div className="button_container">
              <LoadingButton
                variant="contained"
                color="primary"
                onClick={handleNewRowQuestion}
              >
                Add More
              </LoadingButton>
            </div>
            <div className="dn_input_box">
              <TextField
                label="Author Name"
                type="text"
                id="text"
                className="input_style"
                value={Name}
                onChange={(e) => setName(e.target.value)}
                autocomplete="off"
                size="small"
              />
            </div>
            <div className="dn_input_box">
              <TextField
                label="Author Email"
                type="text"
                id="text"
                className="input_style"
                error={emailError !== "" ? true : false}
                helperText={emailError}
                value={email}
                onChange={(e) => emailChange(e.target.value)}
                autocomplete="off"
                size="small"
              />
            </div>
            <div className="dn_input_box">
              <TextField
                label="Youtube Link"
                type="text"
                id="text"
                className="input_style"
                value={blogVideoYoutubeLink}
                onChange={(e) => setblogVideoYoutubeLink(e.target.value)}
                autocomplete="off"
                size="small"
              />
            </div>
            <div className="dn_input_box">
              <TextField
                label="Twitter Link"
                type="text"
                id="text"
                className="input_style"
                value={blogVideoTwitterLink}
                onChange={(e) => setblogVideoTwitterLink(e.target.value)}
                autocomplete="off"
                size="small"
              />
            </div>
            <div className="dn_input_box">
              <TextField
                label="Facebook Link"
                type="text"
                id="text"
                className="input_style"
                value={blogVideoFacebooxLink}
                onChange={(e) => setblogVideoFacebooxLink(e.target.value)}
                autocomplete="off"
                size="small"
              />
            </div>
            <div className="dn_input_box">
              <TextField
                label=" Instagram Link"
                type="text"
                id="text"
                className="input_style"
                value={blogVideoInstagramLink}
                onChange={(e) => setblogVideoInstagramLink(e.target.value)}
                autocomplete="off"
                size="small"
              />
            </div>
            <div className="dn_input_box">
              <TextField
                label="LinkedIn Link"
                type="text"
                id="text"
                className="input_style"
                value={blogVideoLinkedInLink}
                onChange={(e) => setblogVideoLinkedInLink(e.target.value)}
                autocomplete="off"
                size="small"
              />
            </div>

            <div className="dn_input_box">
              <div className="input_style"></div>
            </div>
            <div
              style={{
                width: "100%",
              }}
            >
              <h6>Reference</h6>
              <ReactQuill
                theme="snow"
                value={referance}
                onChange={(e) => setreferance(e)}
              />
            </div>
            <div className="button_container">
              <LoadingButton
                variant="contained"
                color="primary"
                onClick={(e) => handlesubmit(e)}
                disabled={btnLoading}
              >
                {btnLoading ? (
                  <>
                    LOading
                    <LoadingIndicator size={24} />
                  </>
                ) : (
                  "Submit"
                )}
              </LoadingButton>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Add_Blogs;
