import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import MenuItem from "@mui/material/MenuItem";
import { TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { PiPaperPlaneRight } from "react-icons/pi";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import CircularProgress from "@mui/material/CircularProgress";
import { Typography, message, Radio } from "antd";
import { read, utils } from "xlsx";
import { createBulkProgram } from "../../../redux/Program/ProgramSlice";
import cip_codes from "../all_cip_codes.json";
import cip_codes_4_digit from "../all_cip_4_digit.json";
import cip_codes_resorce from "../resorce_cip_datas.json";

const AddBulkProgram = () => {
  const [btnPress, setbtnPress] = useState(false);
  const [data, setData] = useState("");
  const [slugUrlError, setSlugUrlError] = useState("");
  const [addUniversitySuccess, setaddUniversitySuccess] = useState("");

  const [btnLoading, setbtnLoading] = useState(false);

  const dispatch = useDispatch();

  const handlefile = async (e) => {
    const file = e.target.files[0];
    const f = await file.arrayBuffer();
    const wb = read(f);
    const worksheetname = wb.SheetNames[0];
    const worksheet = wb.Sheets[worksheetname];
    const datas = utils.sheet_to_json(worksheet);
    console.log(datas);
    setData(datas);
  };

  const handlesubmit = async (e) => {
    e.preventDefault();
    setbtnLoading(true);
    for (let i in data) {
      let count = 0;
      try {
        let myrow = data[i];

        let name = String(myrow.INSTNM);
        let programname = String(myrow.CIPDESC);

        try {
          const find_program = cip_codes_4_digit.find(
            (data) => String(data.Title) === String(programname)
          );
          try {
            const formData = {
              unversityName: name,
              UNITID: String(myrow.UNITID),
              OPEID6: String(myrow.OPEID6),
              CIPCODE: String(find_program.CIPCode),
              programName: String(find_program.Title),
              duration: "",
              programFees: "",
              description: "",
              intake: "",
              eligibility: "",
              educationLevel: String(myrow.CREDDESC),
              CREDLEV: String(myrow.CREDLEV),
              universityType: String(myrow.CONTROL),
              applyLink: "",
              applyFee1: "",
            };
            const newObject = {};
            for (const key in formData) {
              newObject[key] =
                formData[key] === null || formData[key] === "NULL"
                  ? ""
                  : formData[key];
            }
            const adduniversity = await dispatch(createBulkProgram(newObject));
            console.log(adduniversity.payload, "adduniversity");
            if (adduniversity.payload.success) {
              setaddUniversitySuccess(`${name} university add successfully`);
            }
          } catch (error) {}
        } catch (error) {}
      } catch (error) {}
    }
    setbtnLoading(false);
  };

  const LoadingButton = styled(Button)(({ theme }) => ({
    position: "relative",
  }));

  const LoadingIndicator = styled(CircularProgress)(({ theme }) => ({
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  }));

  return (
    <>
      <div className="main_fragnent_container p-2">
        <div className="profile_container_header">
          <div className="profile_co_header_left">
            <span>Load Bulk Programs</span>
            <span
              style={{
                visibility: "hidden",
              }}
            >
              Student
            </span>
          </div>
          <div className="profile_co_header_right"></div>
        </div>
        <div className="main_recha_container">
          <div className="inputs_container">
            <div className="dn_input_box">
              <div className="input_style">
                <h6>Select File</h6>
                <input
                  type="file"
                  placeholder="enter name....."
                  onChange={(e) => handlefile(e)}
                />
              </div>
            </div>
            <div className="dn_input_box"></div>
            <p style={{ color: "red" }}>{slugUrlError}</p>
            <p style={{ color: "green" }}>{addUniversitySuccess}</p>
            <div className="button_container">
              <LoadingButton
                variant="contained"
                color="primary"
                onClick={(e) => handlesubmit(e)}
                disabled={btnLoading}
              >
                {btnLoading ? (
                  <>
                    LOading
                    <LoadingIndicator size={24} />
                  </>
                ) : (
                  "Submit"
                )}
              </LoadingButton>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddBulkProgram;
