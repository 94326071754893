import React, { useEffect, useState } from "react";
import { AppstoreOutlined } from "@ant-design/icons";
import { Button, Menu } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { FaShoppingBag } from "react-icons/fa";
import { BsCheckCircleFill } from "react-icons/bs";
import { IoPersonCircleOutline, IoReceiptSharp } from "react-icons/io5";
import { AiOutlineTeam } from "react-icons/ai";
import { IoWalletOutline } from "react-icons/io5";
import { FaRegHandshake } from "react-icons/fa6";
import { LiaUniversitySolid } from "react-icons/lia";
import { RiMiniProgramLine } from "react-icons/ri";
import {
  MdAppRegistration,
  MdLockReset,
  MdOutlineManageAccounts,
  MdOutlinePayments,
  MdPassword,
  MdPayments,
} from "react-icons/md";
import { MdOutlinePendingActions } from "react-icons/md";
import { FcMoneyTransfer } from "react-icons/fc";
import { CiCreditCard1 } from "react-icons/ci";
import { GiReceiveMoney } from "react-icons/gi";
import { FaHistory } from "react-icons/fa";
import { LuFilePlus2, LuHistory } from "react-icons/lu";
import { useDispatch } from "react-redux";
import { PiStudentBold } from "react-icons/pi";
import { TbBrandBlogger, TbFileSettings } from "react-icons/tb";
import { CgWebsite } from "react-icons/cg";
import { GrBlog } from "react-icons/gr";
import { useLocation } from "react-router-dom";

const DashboardMenu = ({ menubar_Status, onClose }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [stateOpenKeys, setStateOpenKeys] = useState([]);

  const items = [
    {
      key: "1",
      icon: <AppstoreOutlined />,
      label: "Dashboard",
      routes: "/admin",
    },

    {
      key: "2",
      label: "Student Management",
      icon: <MdOutlineManageAccounts />,
      children: [
        {
          key: "3",
          label: "Students",
          routes: "/admin/registered-student",
          icon: <PiStudentBold />,
        },
        {
          key: "4",
          label: "Payments",
          routes: "/admin/payment-student",
          icon: <MdOutlinePayments />,
        },
      ],
    },
    {
      key: "5",
      label: "University Management",
      icon: <FaRegHandshake />,
      children: [
        {
          key: "6",
          label: "University",
          icon: <LiaUniversitySolid />,
          children: [
            {
              key: "7",
              label: "Add University",
              routes: "/admin/add-university",
              icon: <MdOutlinePendingActions />,
            },
            {
              key: "8",
              label: "Load Universities",
              routes: "/admin/add-bulk-university",
              icon: <MdOutlinePendingActions />,
            },
            {
              key: "9",
              label: "Search Universities",
              routes: "/admin/search-university",
              icon: <MdOutlinePendingActions />,
            },
          ],
        },
        {
          key: "10",
          label: "Program",
          icon: <RiMiniProgramLine />,
          children: [
            {
              key: "33",
              label: "Add Programs",
              routes: "/admin/add-program",
              icon: <MdOutlinePendingActions />,
            },
            {
              key: "11",
              label: "Load Programs",
              routes: "/admin/add-bulk-program",
              icon: <MdOutlinePendingActions />,
            },
            {
              key: "12",
              label: "Search Programs",
              routes: "/admin/search-program",
              icon: <MdOutlinePendingActions />,
            },
          ],
        },
      ],
    },
    {
      key: "13",
      label: "Test Management",
      icon: <TbFileSettings />,
      children: [
        // {
        //   key: "14",
        //   label: "Add Test",
        //   routes: "/admin/add-test",
        //   icon: <LuFilePlus2 />,
        // },

        // {
        //   key: "15",
        //   label: "Available Test",
        //   routes: "/admin/search-test",
        //   icon: <MdOutlinePendingActions />,
        // },

        // {
        //   key: "151",
        //   label: "Pending Test",
        //   routes: "/admin/pending-test",
        //   icon: <MdOutlinePendingActions />,
        // },
        {
          key: "141",
          label: "Add Question",
          routes: "/admin/add-test-paper",
          icon: <LuFilePlus2 />,
        },
        {
          key: "152",
          label: "Grades Report",
          routes: "/admin/grade-report",
          icon: <MdOutlinePendingActions />,
        },
        {
          key: "151",
          label: "Question Bank",
          routes: "/admin/search-test-paper",
          icon: <MdOutlinePendingActions />,
        },
      ],
    },
    {
      key: "16",
      label: "Website Management",
      icon: <CgWebsite />,
      children: [
        {
          key: "17",
          label: "Blog",
          icon: <GrBlog />,
          children: [
            {
              key: "18",
              label: "Add Blog Writing",
              routes: "/admin/add-blogs",
              icon: <TbBrandBlogger />,
            },
            {
              key: "19",
              label: "Blog List",
              routes: "/admin/search-blogs",
              icon: <MdOutlinePendingActions />,
            },
          ],
        },
        {
          key: "20",
          label: "Notifications",
          icon: <FaRegHandshake />,
          children: [
            // {
            //   key: "21",
            //   label: "Add Notifications",
            //   routes: "/admin",
            //   icon: <MdOutlinePendingActions />,
            // },
            {
              key: "22",
              label: "University",
              routes: "/admin/university-notifications",
              icon: <MdOutlinePendingActions />,
            },
            {
              key: "23",
              label: "Student",
              routes: "/admin/student-notifications",
              icon: <MdOutlinePendingActions />,
            },
          ],
        },
        {
          key: "w33",
          label: "Partner University",
          routes: "/admin/partner-university",
          icon: <MdOutlinePayments />,
        },
      ],
    },
    {
      key: "24",
      label: "Admin Management",
      icon: <FaRegHandshake />,
      children: [
        {
          key: "25",
          label: "Add Admin",
          routes: "/admin/add-admin",
          icon: <MdAppRegistration />,
        },
        {
          key: "26",
          label: "Current Admins",
          routes: "/admin/search-admin",
          icon: <MdOutlinePendingActions />,
        },
      ],
    },
    {
      key: "27",
      label: "Reports",
      icon: <FaRegHandshake />,
      children: [
        {
          key: "28",
          label: "Student",
          routes: "/admin/registered-student",
          icon: <MdAppRegistration />,
        },
        {
          key: "29",
          label: "University",
          routes: "/admin/search-university",
          icon: <MdOutlinePendingActions />,
        },
        {
          key: "30",
          label: "TestPrep",
          routes: "/admin/search-test",
          icon: <MdOutlinePendingActions />,
        },
        {
          key: "31",
          label: "Admin",
          routes: "/admin/search-admin",
          icon: <MdOutlinePendingActions />,
        },
        // {
        //   key: "32",
        //   label: "Add majors",
        //   routes: "/admin/add-majorsubjects",
        //   icon: <MdOutlinePendingActions />,
        // },
      ],
    },
  ];

  // useEffect(() => {
  //   if (stateOpenKeys.length === 0) {
  //     const routeName = location.pathname.split("http://localhost:3000").pop();
  //     // const routeName = location.pathname.split("https://admin.f1visa.online").pop();
  //     let filter_key = "";
  //     if (filter_key === "") {
  //       filter_key = items.find((item) => item.routes === routeName);
  //     }
  //     if (filter_key === "") {
  //       const filterdata = items.filter((item) => item?.children.length > 0);
  //       for (const item of filterdata) {
  //         if (item.children) {
  //           filter_key = item.children.find(
  //             (child) => child.routes === routeName
  //           );
  //         }
  //       }
  //     }
  //   }
  // }, [stateOpenKeys]);

  const getLevelKeys = (items1) => {
    const key = {};
    const func = (items2, level = 1) => {
      items2.forEach((item) => {
        if (item.key) {
          key[item.key] = level;
        }
        if (item.children) {
          func(item.children, level + 1);
        }
      });
    };
    func(items1);
    return key;
  };
  const levelKeys = getLevelKeys(items);

  const onOpenChange = (openKeys) => {
    const currentOpenKey = openKeys.find(
      (key) => stateOpenKeys.indexOf(key) === -1
    );
    // open
    if (currentOpenKey !== undefined) {
      const repeatIndex = openKeys
        .filter((key) => key !== currentOpenKey)
        .findIndex((key) => levelKeys[key] === levelKeys[currentOpenKey]);
      setStateOpenKeys(
        openKeys
          // remove repeat key
          .filter((_, index) => index !== repeatIndex)
          // remove current level all child
          .filter((key) => levelKeys[key] <= levelKeys[currentOpenKey])
      );
    } else {
      // close
      setStateOpenKeys(openKeys);
    }
  };

  const navigationclick = (value) => {
    // console.log(value.item.props.routes, "value");
    const nav_link = value.item.props.routes;
    navigate(`${nav_link}`);
    onClose();
  };

  return (
    <>
      <div className="menu_main_container">
        <Menu
          mode="inline"
          expandIcon={menubar_Status === true ? null : ""}
          defaultSelectedKeys={["231"]}
          openKeys={stateOpenKeys}
          onOpenChange={onOpenChange}
          onClick={(e) => navigationclick(e)}
          items={items}
          inlineCollapsed={menubar_Status}
          style={{
            background: "#00324d",
          }}
        />
      </div>
    </>
  );
};

export default DashboardMenu;
