import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FaPrint } from "react-icons/fa6";
import { styled } from "@mui/material/styles";
import { Box, TextField } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TablePagination from "@mui/material/TablePagination";
import { Link, useNavigate } from "react-router-dom";
import { AudioOutlined, SearchOutlined } from "@ant-design/icons";
import moment from "moment/moment";
import { CSVLink } from "react-csv";
import {
  DownOutlined,
  CaretDownOutlined,
  PlusOutlined,
  FileExcelOutlined,
} from "@ant-design/icons";
import { DatePicker, Input, Select, Button, Flex, Modal, message } from "antd";

import axios from "axios";
import { FaRegCheckCircle, FaBell } from "react-icons/fa";
import { LuXCircle } from "react-icons/lu";
import { notificationsPost } from "../../redux/Notifications/Notificationslice";
const { RangePicker } = DatePicker;

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    // backgroundColor: theme.palette.common.black,
    backgroundColor: "#006fa9",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&td, &th": {
    border: 0,
  },
}));

const Registered_Students = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { allRegisteredStudents } = useSelector((store) => store.student);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [filter_data, setfilter_data] = useState([]);
  const [start_date, setstart_date] = useState("");
  const [end_date, setend_date] = useState("");
  const [search, setSearch] = useState("");

  const [modal, setModal] = useState(false);
  const [modal2, setModal2] = useState(false);

  const [selectStudent, setselectStudent] = useState("");
  const [status, setstatus] = useState("");

  const [title, setTitle] = useState("");
  const [titleError, seTitleError] = useState("");
  const [content, setContent] = useState("");
  const [contentError, setContentError] = useState("");

  const [btnPress, setbtnPress] = useState(false);
  const [btnPressLoading, setbtnPressLoading] = useState(false);

  const [messageApi, contextHolder] = message.useMessage();

  useEffect(() => {
    let filter_trans = allRegisteredStudents;
    if (search !== "") {
      filter_trans = filter_trans.filter((transaction) => {
        return (
          transaction.firstname.toLowerCase().match(search.toLowerCase()) ||
          transaction.lastname.toLowerCase().match(search.toLowerCase())
        );
      });
    }
    if (end_date !== "" && start_date !== "") {
      filter_trans = filter_trans.filter(
        (transaction) =>
          moment(transaction.createdAt).format("YYYY-MM-DD") >= start_date &&
          moment(transaction.createdAt).format("YYYY-MM-DD") <= end_date
      );
    }
    setfilter_data([...filter_trans]);
  }, [search, end_date, start_date, allRegisteredStudents]);

  useEffect(() => {
    let export_trabs = [];
    for (let i = 0; i < filter_data.length; i++) {
      let trans_element = filter_data[i];
      let form_data = {};
      form_data = {
        Date: moment(trans_element.createdAt).format("YYYY-MM-DD"),
        Ref_Number: trans_element.txnReferenceId,
        Ack_Number: trans_element.trxn_id,
        Operator_Name: trans_element.billerName,
        Mobile_Number: trans_element.mobile,
        Recharge_Amount: trans_element.amount,
        Status: trans_element.status,
        Message: "",
        Merchant: "",
        Remarks: "",
      };
      export_trabs = [...export_trabs, form_data];
    }
    // setexport_data([...export_trabs]);
  }, [filter_data]);

  const disableFutureDates = (current) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return current && current.toDate() > today;
  };

  const onChange = (date, dateString) => {
    setstart_date(dateString[0]);
    setend_date(dateString[1]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const Selectstudent = async (e, student, status) => {
    setselectStudent(student);
    setstatus(status);
    setModal(true);
  };
  const studentupdate = async (e) => {
    e.preventDefault();

    const formdata = {
      _id: selectStudent._id,
      studentStatus: status,
    };
    const url = `${process.env.REACT_APP_BASE_URL}/api/v1/student/updateby/${formdata._id}`;
    const config = {
      Headers: {
        "content-type": "application/json",
      },
    };
    const resp = await axios.put(url, formdata, config);
    if (resp.data.success === true) {
      let dupdate_data = filter_data.filter(
        (data) => data._id !== selectStudent._id
      );
      dupdate_data = [...dupdate_data, resp.data.student].sort((a, b) =>
        a.createdAt > b.createdAt ? 1 : -1
      );
      setfilter_data([...dupdate_data]);
      setModal(false);
    }
  };

  useEffect(() => {
    if (btnPress === true) {
      if (title.trim() === "") {
        seTitleError("please enter title");
      } else {
        seTitleError("");
      }

      if (content === "") {
        setContentError("please enter mobile");
      } else {
        setContentError("");
      }
    }
  }, [btnPress, title, content]);

  const handlesubmit = async (e) => {
    e.preventDefault();
    setbtnPressLoading(true);
    if (title === "") {
      seTitleError("please enter title");
    } else {
      seTitleError("");
    }
    if (content === "") {
      setContentError("please enter mobile");
    } else {
      setContentError("");
    }

    if (title !== "" && content !== "") {
      setbtnPressLoading(true);
      const formData = {
        Title: title,
        massage: content,
        StudentId: selectStudent._id,
        StudentName: `${selectStudent.firstname} ${selectStudent.lastname} `,
        Role: "Student",
        NotificationStatus: "Read",
        massageChat: [
          { text: content.trim(), Role: "Admin", position: "right" },
        ],
      };
      const blog = await dispatch(notificationsPost(formData));
      if (blog.payload.success) {
        messageApi.open({
          type: "success",
          content: "Massage send successfully",
        });
        setTitle("");
        setContent("");
        setbtnPressLoading(false);
        setModal2(false);
      }
    } else {
      setbtnPress(true);
      setbtnPressLoading(false);
    }
  };

  return (
    <>
      {contextHolder}
      <Modal
        open={modal}
        title=" "
        onOk={(e) => studentupdate(e)}
        onCancel={() => setModal(false)}
        footer={(_, { OkBtn, CancelBtn }) => (
          <>
            <CancelBtn />
            <OkBtn />
          </>
        )}
      >
        <p>
          {" "}
          Do you want to {status}{" "}
          {selectStudent.firstname + " " + selectStudent.lastname}{" "}
        </p>
      </Modal>
      <Modal
        open={modal2}
        title=" "
        onOk={(e) => studentupdate(e)}
        onCancel={() => setModal2(false)}
        footer={[
          <Button key="back" onClick={() => setModal2(false)}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={btnPressLoading}
            onClick={(e) => handlesubmit(e)}
          >
            Submit
          </Button>,
        ]}
      >
        <div className="Model_container">
          <p>
            Do you want to send a notifications to Mr/Mrs{" "}
            {selectStudent.firstname + " " + selectStudent.lastname}
          </p>
          <div className="dn_input_box">
            <TextField
              label="Title"
              type="email"
              id="text"
              error={titleError !== "" ? true : false}
              helperText={titleError}
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              autocomplete="off"
              size="small"
              className="input_style"
            />
          </div>
          <div className="dn_input_box mt-3">
            <TextField
              label="Address Line 1 "
              className="input_style"
              error={contentError !== "" ? true : false}
              helperText={contentError}
              value={content}
              onChange={(e) => setContent(e.target.value)}
              autocomplete="off"
              id="outlined-multiline-static"
              multiline
              rows={4}
            />
          </div>
        </div>
      </Modal>

      <div className="main_fragnent_container p-2">
        <div className="profile_container_header">
          <div className="profile_co_header_left">
            <span>Students Registered</span>
            <span
              style={{
                visibility: "hidden",
              }}
            >
              Student
            </span>
          </div>
          <div className="profile_co_header_right"></div>
        </div>
        <div className="transaction_header">
          <Input
            placeholder="Name Search"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            style={{
              width: "50vh",
            }}
            suffix={
              <SearchOutlined
                style={{
                  fontSize: 16,
                  color: "#1677ff",
                }}
              />
            }
          />
          <RangePicker
            disabledDate={disableFutureDates}
            onChange={onChange}
            format="YYYY-MM-DD"
            // value={selectedDates}
          />
          <TablePagination
            component="div"
            count={0}
            page={0}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            rowsPerPageOptions={[10, 25, 100]}
            labelRowsPerPage="Rows:"
            labelDisplayedRows={() => null}
            SelectProps={{
              inputProps: { "aria-label": "rows" },
              native: true,
            }}
            ActionsComponent={() => null}
          />
        </div>
        <div className="letter_content">
          <Paper sx={{ width: "100%", overflow: "hidden" }}>
            <TableContainer component={Paper} className="customScrollbar">
              <Table
                sx={{ minWidth: 700, border: "none" }}
                aria-label="customized table"
              >
                <TableHead>
                  <TableRow>
                    <StyledTableCell sx={{ border: "none" }}>
                      No
                    </StyledTableCell>
                    <StyledTableCell align="left" sx={{ border: "none" }}>
                      Name
                    </StyledTableCell>
                    <StyledTableCell align="left" sx={{ border: "none" }}>
                      Level
                    </StyledTableCell>
                    {/* <StyledTableCell align="left" sx={{ border: "none" }}>
                      Gender
                    </StyledTableCell> */}
                    <StyledTableCell align="left" sx={{ border: "none" }}>
                      Country
                    </StyledTableCell>
                    <StyledTableCell align="left" sx={{ border: "none" }}>
                      Status
                    </StyledTableCell>
                    <StyledTableCell align="center" sx={{ border: "none" }}>
                      Notifications
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filter_data
                    .slice(page * rowsPerPage, (page + 1) * rowsPerPage)
                    .map((student, index) => (
                      <StyledTableRow key={index}>
                        <StyledTableCell
                          component="th"
                          scope="row"
                          sx={{ border: "none" }}
                        >
                          {index + 1}
                        </StyledTableCell>
                        <StyledTableCell
                          width={350}
                          align="left"
                          sx={{ border: "none" }}
                        >
                          <span
                            style={{
                              cursor: "pointer",
                              color: "blue",
                              textDecoration: "underline",
                            }}
                            onClick={() => {
                              navigate("/admin/student-info", {
                                state: { data: student },
                              });
                            }}
                          >
                            {student.firstname + " " + student.lastname}
                          </span>
                        </StyledTableCell>
                        <StyledTableCell align="left" sx={{ border: "none" }}>
                          {student.degreeName}
                        </StyledTableCell>
                        {/* <StyledTableCell
                          width={120}
                          align="left"
                          sx={{ border: "none" }}
                        >
                          {student.personalInformation.gender}
                        </StyledTableCell> */}
                        <StyledTableCell
                          align="left"
                          width={150}
                          sx={{ border: "none" }}
                        >
                          {student.personalInformation.countryofresidence}
                        </StyledTableCell>
                        <StyledTableCell align="left" sx={{ border: "none" }}>
                          {student.studentStatus}
                        </StyledTableCell>
                        <StyledTableCell
                          align="center"
                          width={150}
                          sx={{ border: "none" }}
                        >
                          <div
                            style={{
                              width: "100%",
                              height: "100%",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center  ",
                            }}
                          >
                            <div
                              className="approve_box"
                              onClick={() => {
                                setselectStudent(student);
                                setModal2(true);
                              }}
                            >
                              <FaBell />
                            </div>
                          </div>
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>

            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={filter_data.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </div>
      </div>
    </>
  );
};

export default Registered_Students;
