import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import MenuItem from "@mui/material/MenuItem";
import { TextField, Autocomplete } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { PiPaperPlaneRight } from "react-icons/pi";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import CircularProgress from "@mui/material/CircularProgress";
import Majorsubjects from "../Majorsubjects.json";

import { Typography, message } from "antd";
import { createProgram } from "../../../redux/Program/ProgramSlice";

const Add_program = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { totalUniversity } = useSelector((store) => store.university);
  const { major_subjects } = useSelector((state) => state.porgrams);
  const [university, setUniversity] = useState("");
  const [universityError, setUniversityError] = useState("");
  const [universityId, setUniversityId] = useState("");
  const [degreeName, setDegreeName] = useState("");
  const [degreeNameError, setDegreeNameError] = useState("");
  const [program, setProgram] = useState("");
  const [programError, setProgramError] = useState("");
  const [duration, setDuration] = useState("");
  const [durationError, setDurationError] = useState("");
  const [description, setDescription] = useState("");
  const [descriptionError, setDescriptionError] = useState("");
  const [tutionFee, setTutionFee] = useState("");
  const [tutionFeeError, setTutionFeeError] = useState("");
  const [Intake, setIntake] = useState("");
  const [IntakeError, setIntakeError] = useState("");
  const [applyLink, setapplyLink] = useState("");
  const [applyLinkError, setapplyLinkError] = useState("");
  const [applyFee1, setapplyFee1] = useState("");
  const [applyFee1Error, setapplyFee1Error] = useState("");

  const [btnPress, setbtnPress] = useState(false);
  const [btnLoading, setbtnLoading] = useState(false);

  const [messageApi, contextHolder] = message.useMessage();

  useEffect(() => {
    if (btnPress === true) {
      if (university === "") {
        setUniversityError("Required");
      } else {
        setUniversityError("");
      }
      if (degreeName === "") {
        setDegreeNameError("Required");
      } else {
        setDegreeNameError("");
      }
      if (program === "") {
        setProgramError("Required");
      } else {
        setProgramError("");
      }
      // if (duration === "") {
      //   setDurationError("Required");
      // } else {
      //   setDurationError("");
      // }
      // if (description === "") {
      //   setDescriptionError("Required");
      // } else {
      //   setDescriptionError("");
      // }
      // if (tutionFee === "") {
      //   setTutionFeeError("Required");
      // } else {
      //   setTutionFeeError("");
      // }
      // if (tutionFee === "") {
      //   setIntakeError("Required");
      // } else {
      //   setIntakeError("");
      // }
      // if (applyLink === "") {
      //   setapplyLinkError("Required");
      // } else {
      //   setapplyLinkError("");
      // }
      // if (applyFee1 === "") {
      //   setapplyFee1Error("Required");
      // } else {
      //   setapplyFee1Error("");
      // }
    }
  }, [
    btnPress,
    university,
    degreeName,
    program,
    duration,
    description,
    tutionFee,
    tutionFee,
    applyLink,
    applyFee1,
  ]);

  const SelectDegree = [
    {
      id: 1,
      name: "Undergraduate",
    },
    {
      id: 2,
      name: "Graduate",
    },
    {
      id: 3,
      name: "Doctoral",
    },
  ];

  const handleSubmit = async (e) => {
    e.preventDefault();
    setbtnLoading(true);
    setbtnPress(true);
    if (university === "") {
      setUniversityError("Required");
    } else {
      setUniversityError("");
    }
    if (degreeName === "") {
      setDegreeNameError("Required");
    } else {
      setDegreeNameError("");
    }
    if (program === "") {
      setProgramError("Required");
    } else {
      setProgramError("");
    }
    // if (duration === "") {
    //   setDurationError("Required");
    // } else {
    //   setDurationError("");
    // }
    // if (description === "") {
    //   setDescriptionError("Required");
    // } else {
    //   setDescriptionError("");
    // }
    // if (tutionFee === "") {
    //   setTutionFeeError("Required");
    // } else {
    //   setTutionFeeError("");
    // }
    // if (tutionFee === "") {
    //   setIntakeError("Required");
    // } else {
    //   setIntakeError("");
    // }
    // if (applyLink === "") {
    //   setapplyLinkError("Required");
    // } else {
    //   setapplyLinkError("");
    // }
    // if (applyFee1 === "") {
    //   setapplyFee1Error("Required");
    // } else {
    //   setapplyFee1Error("");
    // }

    if (
      university !== "" &&
      degreeName !== "" &&
      program !== ""
      // &&
      // duration !== "" &&
      // description !== "" &&
      // Intake !== "" &&
      // tutionFee !== "" &&
      // applyLink !== "" &&
      // applyFee1 !== "" &&
      // tutionFee !== ""
    ) {
      const getuniver = totalUniversity.find(
        (data) => data.universityName === university
      );
      const getmajor = Majorsubjects.find((data) => data.name === program);

      let eduLeve = "";
      if (degreeName === "Undergraduate") {
        eduLeve = "Bachelor's Degree";
      } else if (degreeName === "Graduate") {
        eduLeve = "Master's Degree";
      } else if (degreeName === "Doctoral") {
        eduLeve = "Doctoral Degree";
      }

      const formData = {
        unversityName: university,
        universityId: universityId,
        UNITID: getuniver.UNITID,
        OPEID6: getuniver.OPEID,
        programName: program,
        CIPCODE: getmajor.code.slice(0, 5),
        duration: duration,
        tutionFees: tutionFee,
        description: description,
        intake: Intake,
        // eligibility: degreeName,
        educationLevel: eduLeve,
        applyLink: applyLink,
        applyFee1: applyFee1,
      };
      const programPost = await dispatch(createProgram(formData));
      if (programPost.payload.success) {
        messageApi.open({
          type: "success",
          content: "Program created successfully",
        });
        setUniversity("");
        setUniversityId("");
        setProgram("");
        setDuration("");
        setDescription("");
        setTutionFee("");
        setIntake("");
        setapplyFee1("");
        setapplyLink("");
        setbtnPress(false);
        setbtnLoading(false);
      } else {
        setbtnLoading(false);
      }
    } else {
      setbtnPress(true);
      setbtnLoading(false);
    }
  };

  const selectListedUniversityChange = (e) => {
    const value = e;
    const selectedItem = totalUniversity.find(
      (item) => item.universityName === value
    );
    setUniversity(value);
    setUniversityId(selectedItem._id);
  };
  const selectDegreeChange = (e) => {
    const value = e.target.value;
    setDegreeName(value);
    setProgram("");
  };

  const LoadingButton = styled(Button)(({ theme }) => ({
    position: "relative",
  }));

  const LoadingIndicator = styled(CircularProgress)(({ theme }) => ({
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  }));

  return (
    <>
      {contextHolder}
      <div className="main_fragnent_container p-2">
        <div className="profile_container_header">
          <div className="profile_co_header_left">
            <span>Add Program</span>
            <span
              style={{
                visibility: "hidden",
              }}
            >
              Student
            </span>
          </div>
          <div className="profile_co_header_right"></div>
        </div>
        <div className="main_recha_container">
          <div className="inputs_container">
            <div className="dn_input_box">
              {/* <TextField
                id="select"
                label="Listed University"
                select
                className="input_style"
                error={universityError !== "" ? true : false}
                helperText={universityError}
                value={university}
                onChange={(e) => {
                  selectListedUniversityChange(e);
                }}
                size="small"
              >
                {totalUniversity &&
                  totalUniversity.map((data, index) => (
                    <MenuItem key={index} value={data.universityName}>
                      {data.universityName}
                    </MenuItem>
                  ))}
              </TextField> */}

              <Autocomplete
                options={totalUniversity}
                getOptionLabel={(option) => option.universityName}
                value={totalUniversity.find(
                  (c) => c.universityName === university
                )}
                onChange={(event, newValue) => {
                  if (newValue) {
                    selectListedUniversityChange(newValue.universityName);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Listed University"
                    error={universityError !== "" ? true : false}
                    helperText={universityError}
                    size="small"
                  />
                )}
                className="input_style"
              />
            </div>
            <div className="dn_input_box">
              <TextField
                id="select"
                label="Choose a program"
                select
                className="input_style"
                error={degreeNameError !== "" ? true : false}
                helperText={degreeNameError}
                value={degreeName}
                onChange={(e) => {
                  selectDegreeChange(e);
                }}
                size="small"
              >
                {SelectDegree &&
                  SelectDegree.map((data, index) => (
                    <MenuItem key={index} value={data.name}>
                      {data.name}
                    </MenuItem>
                  ))}
              </TextField>
            </div>
            <div className="dn_input_box">
              <Autocomplete
                options={Majorsubjects.sort((a, b) =>
                  a.name > b.name ? 1 : -1
                )}
                getOptionLabel={(option) => option.name}
                value={Majorsubjects.find((c) => c.name === program)}
                onChange={(event, newValue) => {
                  if (newValue) {
                    setProgram(newValue.name);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Listed Programes"
                    error={programError !== "" ? true : false}
                    helperText={programError}
                    size="small"
                  />
                )}
                className="input_style"
              />
            </div>
            <div className="dn_input_box">
              <TextField
                label="Duration in months"
                type="text"
                id="text"
                className="input_style"
                error={durationError !== "" ? true : false}
                helperText={durationError}
                value={duration}
                onChange={(e) => setDuration(e.target.value.replace(/\D/g, ""))}
                autocomplete="off"
                size="small"
              />
            </div>
            <div className="dn_input_box">
              <TextField
                label="Description"
                type="text"
                id="text"
                className="input_style"
                error={descriptionError !== "" ? true : false}
                helperText={descriptionError}
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                autocomplete="off"
                size="small"
              />
            </div>
            <div className="dn_input_box">
              <TextField
                label="Tuition Fees"
                type="text"
                id="text"
                className="input_style"
                error={tutionFeeError !== "" ? true : false}
                helperText={tutionFeeError}
                value={tutionFee}
                onChange={(e) => setTutionFee(e.target.value)}
                autocomplete="off"
                size="small"
              />
            </div>
            <div className="dn_input_box">
              <TextField
                label="Intake"
                type="text"
                id="text"
                className="input_style"
                error={IntakeError !== "" ? true : false}
                helperText={IntakeError}
                value={Intake}
                onChange={(e) => setIntake(e.target.value)}
                autocomplete="off"
                size="small"
              />
            </div>
            <div className="dn_input_box">
              <TextField
                label="Apply Link"
                type="text"
                id="text"
                className="input_style"
                error={applyLinkError !== "" ? true : false}
                helperText={applyLinkError}
                value={applyLink}
                onChange={(e) => setapplyLink(e.target.value)}
                autocomplete="off"
                size="small"
              />
            </div>
            <div className="dn_input_box">
              <TextField
                label="Apply Fees"
                type="text"
                id="text"
                className="input_style"
                error={applyFee1Error !== "" ? true : false}
                helperText={applyFee1Error}
                value={applyFee1}
                onChange={(e) => setapplyFee1(e.target.value)}
                autocomplete="off"
                size="small"
              />
            </div>
            <div className="dn_input_box">
              <div className="input_style"></div>
            </div>
            <div className="button_container">
              <LoadingButton
                variant="contained"
                color="primary"
                onClick={(e) => handleSubmit(e)}
                disabled={btnLoading}
              >
                {btnLoading ? (
                  <>
                    LOading
                    <LoadingIndicator size={24} />
                  </>
                ) : (
                  "Submit"
                )}
              </LoadingButton>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Add_program;
