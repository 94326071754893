import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import axios from "axios";
import { compress, decompress } from 'lz-string';
const Baseurl = process.env.REACT_APP_BASE_URL
const initialState = {
    programTotal: [],
    isProgramLoading: true,
    totalPrograms: localStorage.getItem("totalPrograms")
        ? JSON.parse(decompress(localStorage.getItem("totalPrograms")))
        : [],
    isProgramAvailable: localStorage.getItem("totalPrograms")
        ? true
        : false,
    programLoading: true,
    major_subjects: localStorage.getItem("major_subjects")
        ? JSON.parse(decompress(localStorage.getItem("major_subjects")))
        : [],
    isMajor_subjectsAvailable: localStorage.getItem("major_subjects")
        ? true
        : false,
    major_subjectsLoading: true,
}

export const createProgram = createAsyncThunk(
    "program,createprogram",
    async (formData, thunkAPI) => {
        try {
            const config = {
                Headers: {
                    "content-type": "application/json",
                },
            }
            const url = `${Baseurl}/api/v1/program/new`
            const resp = await axios.post(url, formData, config)
            return resp.data
        } catch (error) {
            return thunkAPI.rejectWithValue("Program not able to create")
        }
    }
)
export const createBulkProgram = createAsyncThunk(
    "program,createBulkProgram",
    async (formData, thunkAPI) => {
        try {
            const config = {
                Headers: {
                    "content-type": "application/json",
                },
            }
            const url = `${Baseurl}/api/v1/program/bulkprogram`
            const resp = await axios.post(url, formData, config)
            return resp.data
        } catch (error) {
            return thunkAPI.rejectWithValue("Program not able to create")
        }
    }
)
export const getAllProgram = createAsyncThunk(
    "program,getAllProgram",
    async (thunkAPI) => {
        try {
            const url = `${Baseurl}/api/v1/program/all`;
            const resp = await axios.get(url);
            return resp.data
        } catch (error) {
            return thunkAPI.rejectWithValue("Programs are not able to fetch")
        }
    }
)
export const updateProgram = createAsyncThunk(
    "program,updateprogram",
    async (formData, thunkAPI) => {
        try {
            const config = {
                Headers: {
                    "content-type": "application/json",
                },
            }
            const url = `${Baseurl}/api/v1/program/updateby/${formData._id}`
            const resp = await axios.put(url, formData, config)
            return resp.data
        } catch (error) {
            return thunkAPI.rejectWithValue("program is not able to update")
        }
    }
)

export const deleteProgram = createAsyncThunk(
    "program,deleteprogram",
    async (_id, thunkAPI) => {
        try {
            const url = `${Baseurl}/api/v1/deleteby/${_id}`;
            const resp = await axios.get(url);
            console.log(resp.data);
            return resp.data
        } catch (error) {
            return thunkAPI.rejectWithValue("Program not able to delete")
        }
    }
)
export const getAllMajor_subjects = createAsyncThunk(
    "test/getAllMajor_subjects",
    async (formData, thunkAPI) => {
        try {
            const url = `${Baseurl}/api/v1/majorsubjects/all`;
            const resp = await axios.get(url);
            return resp.data
        } catch (error) {
            return thunkAPI.rejectWithValue("Test not able to fetch ")
        }
    }
)

const programSlice = createSlice({
    name: "porgrams",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(createProgram.pending, (state) => {
                state.isProgramLoading = true
            })
            .addCase(createProgram.fulfilled, (state, action) => {
                if (action.payload.success) {
                    state.programTotal = [action.payload.program, ...state.programTotal]
                }
                state.isProgramLoading = false
            })
            .addCase(createProgram.rejected, (state) => {
                state.isProgramLoading = true
            })
            .addCase(createBulkProgram.pending, (state) => {
                state.isProgramLoading = true
            })
            .addCase(createBulkProgram.fulfilled, (state, action) => {
                if (action.payload.success) {
                    state.programTotal = [action.payload.program, ...state.programTotal]
                }
                state.isProgramLoading = false
            })
            .addCase(createBulkProgram.rejected, (state) => {
                state.isProgramLoading = true
            })
            .addCase(getAllProgram.pending, (state) => {
                state.isProgramLoading = true
                state.programLoading = true;
            })
            .addCase(getAllProgram.fulfilled, (state, action) => {
                if (action.payload.success) {
                    state.programTotal = action.payload.programs
                    state.totalPrograms = action.payload.programs
                    localStorage.setItem("totalPrograms", compress(JSON.stringify(state.totalPrograms)));
                }
                state.isProgramLoading = false
                state.programLoading = false;
                state.isProgramAvailable = true;
            })
            .addCase(getAllProgram.rejected, (state) => {
                state.isProgramLoading = true
                state.programLoading = true;
            })
            .addCase(updateProgram.pending, (state) => {
                state.isProgramLoading = true
            })
            .addCase(updateProgram.fulfilled, (state, action) => {

                if (action.payload.success) {
                    state.programTotal = state.programTotal.filter((program) =>
                        program._id === action.payload._id
                    )
                    state.programTotal = [action.payload.program, ...state.programTotal]
                }
                state.isProgramLoading = false
            })
            .addCase(updateProgram.rejected, (state) => {
                state.isProgramLoading = true
            })
            .addCase(deleteProgram.pending, (state) => {
                state.isProgramLoading = true
            })
            .addCase(deleteProgram.fulfilled, (state, action) => {

                if (action.payload.success) {
                    state.programTotal = state.programTotal.filter((program) =>
                        program._id === action.payload._id
                    )
                }
                state.isProgramLoading = false
            })
            .addCase(deleteProgram.rejected, (state) => {
                state.isProgramLoading = true
            })
            .addCase(getAllMajor_subjects.pending, (state) => {
                state.major_subjectsLoading = true;
            })
            .addCase(getAllMajor_subjects.fulfilled, (state, action) => {
                if (action.payload.success) {
                    state.major_subjects = action.payload.majorSubject
                    localStorage.setItem("major_subjects", compress(JSON.stringify(state.major_subjects)));
                }
                state.isMajor_subjectsAvailable = false;
                state.major_subjectsLoading = true;
            })
            .addCase(getAllMajor_subjects.rejected, (state) => {
                state.major_subjectsLoading = true;
            })
    }
})

export const { } = programSlice.actions
export default programSlice.reducer;