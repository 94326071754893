import React from "react";
import { useLocation } from "react-router-dom";

const StudentInfo = () => {
  const location = useLocation();
  const studentData = location.state?.data;

  return (
    <>
      <div className="main_fragnent_container p-2">
        <div className="profile_container_header">
          <div className="profile_co_header_left">
            <span>Student Details</span>
            <span
              style={{
                visibility: "hidden",
              }}
            >
              Student
            </span>
          </div>
          <div className="profile_co_header_right"></div>
        </div>

        <div className="personal_details_container">
          <div className="viewdatainput">
            <div className="input_style">
              <p>
                {" "}
                <span>Country of birth</span> :{" "}
                <span style={{ color: "#000" }}>
                  {studentData.personalInformation.countryofbirth}
                </span>
              </p>
            </div>
          </div>
          <div className="viewdatainput">
            <div className="input_style">
              <p>
                {" "}
                <span>Primary Nationality</span> :{" "}
                <span style={{ color: "#000" }}>
                  {studentData.personalInformation.primarynational}
                </span>
              </p>
            </div>
          </div>
          <div className="viewdatainput">
            <div className="input_style">
              <p>
                {" "}
                <span>County of residence</span> :{" "}
                <span style={{ color: "#000" }}>
                  {studentData.personalInformation.countryofresidence}
                </span>
              </p>
            </div>
          </div>
          <div className="viewdatainput">
            <div className="input_style">
              <p>
                {" "}
                <span>Secondary residency</span> :
                <span style={{ color: "#000" }}>
                  {" "}
                  {studentData.personalInformation.secondaryresidence}
                </span>
              </p>
            </div>
          </div>
          <div className="viewdatainput">
            <div className="input_style">
              <p>
                {" "}
                <span>Gender</span> :{" "}
                <span style={{ color: "#000" }}>
                  {" "}
                  {studentData.personalInformation.gender}
                </span>
              </p>
            </div>
          </div>
          <div className="viewdatainput">
            <div className="input_style">
              <p>
                {" "}
                <span>Ethnicity</span> :{" "}
                <span style={{ color: "#000" }}>
                  {studentData.personalInformation.ethnicity}
                </span>
              </p>
            </div>
          </div>
          <div className="viewdatainput">
            <div className="input_style">
              <p>
                {" "}
                <span>Marital Status</span> :{" "}
                <span style={{ color: "#000" }}>
                  {studentData.personalInformation.maritalsts}
                </span>
              </p>
            </div>
          </div>
          <div className="viewdatainput">
            <div className="input_style">
              <p>
                {" "}
                <span>Address Line1</span> :{" "}
                <span style={{ color: "#000" }}>
                  {studentData.personalInformation.addressOne}
                </span>
              </p>
            </div>
          </div>
          <div className="viewdatainput">
            <div className="input_style">
              <p>
                {" "}
                <span>Address Line2</span> :{" "}
                <span style={{ color: "#000" }}>
                  {studentData.personalInformation.addressTwo}
                </span>
              </p>
            </div>
          </div>
          <div className="viewdatainput">
            <div className="input_style">
              <p>
                {" "}
                <span>Country</span> :{" "}
                <span style={{ color: "#000" }}>
                  {studentData.personalInformation.countryofresidence}
                </span>
              </p>
            </div>
          </div>
          <div className="viewdatainput">
            <div className="input_style">
              <p>
                {" "}
                <span>State/Province</span> :{" "}
                <span style={{ color: "#000" }}>
                  {studentData.personalInformation.state}
                </span>
              </p>
            </div>
          </div>
          <div className="viewdatainput">
            <div className="input_style">
              <p>
                {" "}
                <span>City</span> :{" "}
                <span style={{ color: "#000" }}>
                  {studentData.personalInformation.city}
                </span>
              </p>
            </div>
          </div>
          <div className="viewdatainput">
            <div className="input_style">
              <p>
                {" "}
                <span>ZIP/Postal Code</span> :{" "}
                <span style={{ color: "#000" }}>
                  {studentData.personalInformation.zipcode}
                </span>
              </p>
            </div>
          </div>
          <div className="viewdatainput">
            <div className="input_style">
              <p>
                {" "}
                <span>Phone Number</span> :{" "}
                <span style={{ color: "#000" }}>
                  {studentData.personalInformation.phoneNo}
                </span>
              </p>
            </div>
          </div>
          {/* <div className="viewdatainput">
              <div className="input_style">
                <p>
                  {" "}
                  <span>Phone Number</span> :{" "}
                  <span style={{ color: "#000" }}>
                    {studentData.personalInformation.phoneNo}
                  </span>
                </p>
              </div>
            </div> */}
        </div>
        <div className="personal_details_container">
          <div className="viewdatainput">
            <div className="input_style">
              <p>
                {" "}
                <span>Passport Number</span> :{" "}
                <span style={{ color: "#000" }}>
                  {studentData.travelData.passwordNo}
                </span>
              </p>
            </div>
          </div>
          <div className="viewdatainput">
            <div className="input_style">
              <p>
                {" "}
                <span>Issued by</span> :{" "}
                <span style={{ color: "#000" }}>
                  {studentData.travelData.passwordIssue}
                </span>
              </p>
            </div>
          </div>
        </div>
        <div className="personal_details_container">
          <div className="viewdatainput">
            <div className="input_style">
              <p>
                {" "}
                <span>Program Year</span> :{" "}
                <span style={{ color: "#000" }}>
                  {studentData.admissionSession.programYear}
                </span>
              </p>
            </div>
          </div>
          <div className="viewdatainput">
            <div className="input_style">
              <p>
                {" "}
                <span>Program Session</span> :{" "}
                <span style={{ color: "#000" }}>
                  {studentData.admissionSession.programSession}
                </span>
              </p>
            </div>
          </div>
        </div>
        <div className="personal_details_container">
          <div className="viewdatainput">
            <div className="input_style">
              <p>
                {" "}
                <span>Institute Name</span> :{" "}
                <span style={{ color: "#000" }}>
                  {studentData.education.GraduationOption[0].institute}
                </span>
              </p>
            </div>
          </div>
          <div className="viewdatainput">
            <div className="input_style">
              <p>
                {" "}
                <span>Diploma/Degree</span> :{" "}
                <span style={{ color: "#000" }}>
                  {studentData.education.GraduationOption[0].degree}
                </span>
              </p>
            </div>
          </div>
          <div className="viewdatainput">
            <div className="input_style">
              <p>
                {" "}
                <span>Country</span> :{" "}
                <span style={{ color: "#000" }}>
                  {studentData.education.GraduationOption[0].country}
                </span>
              </p>
            </div>
          </div>
          <div className="viewdatainput">
            <div className="input_style">
              <p>
                {" "}
                <span>City</span> :{" "}
                <span style={{ color: "#000" }}>
                  {studentData.education.GraduationOption[0].city}
                </span>
              </p>
            </div>
          </div>
          <div className="viewdatainput">
            <div className="input_style">
              <p>
                {" "}
                <span>Month Graduated or Expected</span> :{" "}
                <span style={{ color: "#000" }}>
                  {studentData.education.GraduationOption[0].graduationMonth}
                </span>
              </p>
            </div>
          </div>
          <div className="viewdatainput">
            <div className="input_style">
              <p>
                {" "}
                <span>Year Graduated or Expected</span> :{" "}
                <span style={{ color: "#000" }}>
                  {studentData.education.GraduationOption[0].graduationYear}
                </span>
              </p>
            </div>
          </div>
          <div className="viewdatainput">
            <div className="input_style">
              <p>
                {" "}
                <span>GPA</span> :{" "}
                <span style={{ color: "#000" }}>
                  {studentData.education.GraduationOption[0].gpa}
                </span>
              </p>
            </div>
          </div>
          <div className="viewdatainput">
            <div className="input_style">
              <p>
                {" "}
                <span>Major</span> :{" "}
                <span style={{ color: "#000" }}>
                  {studentData.education.GraduationOption[0].AdditionalSubject1}
                </span>
              </p>
            </div>
          </div>
          <div className="viewdatainput">
            <div className="input_style">
              <p>
                {" "}
                <span>2nd Major</span> :{" "}
                <span style={{ color: "#000" }}>
                  {studentData.education.GraduationOption[0].AdditionalSubject2}
                </span>
              </p>
            </div>
          </div>
        </div>

        <div className="personal_details_container">
          <div className="viewdatainput">
            <div className="input_style">
              <p>
                {" "}
                <span>Employer</span> :{" "}
                <span style={{ color: "#000" }}>
                  {studentData.workExperience[0].Employer}
                </span>
              </p>
            </div>
          </div>
          <div className="viewdatainput">
            <div className="input_style">
              <p>
                {" "}
                <span>country</span> :{" "}
                <span style={{ color: "#000" }}>
                  {studentData.workExperience[0].Country}
                </span>
              </p>
            </div>
          </div>
          <div className="viewdatainput">
            <div className="input_style">
              <p>
                {" "}
                <span>City</span> :{" "}
                <span style={{ color: "#000" }}>
                  {studentData.workExperience[0].country}
                </span>
              </p>
            </div>
          </div>
          <div className="viewdatainput">
            <div className="input_style">
              <p>
                {" "}
                <span>From Year</span> :{" "}
                <span style={{ color: "#000" }}>
                  {studentData.workExperience[0].StartYear}
                </span>
              </p>
            </div>
          </div>
          <div className="viewdatainput">
            <div className="input_style">
              <p>
                {" "}
                <span>From Month</span> :{" "}
                <span style={{ color: "#000" }}>
                  {studentData.workExperience[0].StartMonth}
                </span>
              </p>
            </div>
          </div>
          <div className="viewdatainput">
            <div className="input_style">
              <p>
                {" "}
                <span>To Year</span> :{" "}
                <span style={{ color: "#000" }}>
                  {studentData.workExperience[0].EndYear}
                </span>
              </p>
            </div>
          </div>
          <div className="viewdatainput">
            <div className="input_style">
              <p>
                {" "}
                <span>To Month</span> :{" "}
                <span style={{ color: "#000" }}>
                  {studentData.workExperience[0].EndMonth}
                </span>
              </p>
            </div>
          </div>
          <div className="viewdatainput">
            <div className="input_style">
              <p>
                {" "}
                <span>Domain Area</span> :{" "}
                <span style={{ color: "#000" }}>
                  {studentData.workExperience[0].DomainArea}
                </span>
              </p>
            </div>
          </div>
          <div className="viewdatainput">
            <div className="input_style">
              <p>
                {" "}
                <span>Skills used</span> :{" "}
                <span style={{ color: "#000" }}>
                  {studentData.workExperience[0].SkilsUsed}
                </span>
              </p>
            </div>
          </div>
        </div>
        <div className="personal_details_container">
          <div className="viewdatainput">
            <div className="input_style">
              <p>
                {" "}
                <span>English Exam Type</span> :{" "}
                <span style={{ color: "#000" }}>
                  {studentData.testScore.englishexamType}
                </span>
              </p>
            </div>
          </div>
          <div className="viewdatainput">
            <div className="input_style">
              <p>
                {" "}
                <span>Total Score</span> :{" "}
                <span style={{ color: "#000" }}>
                  {studentData.testScore.englishTotalScore}
                </span>
              </p>
            </div>
          </div>
          <div className="viewdatainput">
            <div className="input_style">
              <p>
                {" "}
                <span>Test Date</span> :{" "}
                <span style={{ color: "#000" }}>
                  {studentData.testScore.englishTestDate}
                </span>
              </p>
            </div>
          </div>
          <div className="viewdatainput">
            <div className="input_style">
              <p>
                {" "}
                <span>Admission Test Type</span> :{" "}
                <span style={{ color: "#000" }}>
                  {studentData.testScore.admissionExamName}
                </span>
              </p>
            </div>
          </div>
          <div className="viewdatainput">
            <div className="input_style">
              <p>
                {" "}
                <span>Total Score</span> :{" "}
                <span style={{ color: "#000" }}>
                  {studentData.testScore.admissioneScore}
                </span>
              </p>
            </div>
          </div>

          <div className="viewdatainput">
            <div className="input_style">
              <p>
                {" "}
                <span>Test Date</span> :{" "}
                <span style={{ color: "#000" }}>
                  {studentData.testScore.admissioneDateOfTest}
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default StudentInfo;
