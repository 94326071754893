import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import MenuItem from "@mui/material/MenuItem";
import { TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { PiPaperPlaneRight } from "react-icons/pi";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import CircularProgress from "@mui/material/CircularProgress";
import { Typography, message } from "antd";
import {
  adminPost,
  valiadteEmail,
  valiadteNumber,
} from "../../redux/Authentication/AuthSlice";

const Add_Admin = () => {
  const dispatch = useDispatch();

  const [email, setEmail] = useState("");
  const [Cnfemail, setCnfEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [mobile, setMobile] = useState("");
  const [mobileCnf, setMobileCnf] = useState("");
  const [mobileError, setMobileError] = useState("");
  const [name, setName] = useState("");
  const [nameError, setNameError] = useState("");
  const [Password, setPassword] = useState("");
  const [PasswordError, setPasswordError] = useState("");

  const [WebsiteManagement, setWebsiteManagement] = useState(false);
  const [WebsiteManagementError, setWebsiteManagementError] = useState("");
  const [UniversityManagement, setUniversityManagement] = useState(false);
  const [UniversityManagementError, setUniversityManagementError] =
    useState("");
  const [TestManagement, setTestManagement] = useState(false);
  const [TestManagementError, setTestManagementError] = useState("");
  const [StudentManagent, setStudentManagent] = useState(false);
  const [StudentManagentError, setStudentManagentError] = useState("");

  const [btnPress, setbtnPress] = useState(false);
  const [btnLoading, setbtnLoading] = useState(false);

  const [messageApi, contextHolder] = message.useMessage();

  useEffect(() => {
    if (btnPress === true) {
      if (name === "") {
        setNameError("Required");
      } else {
        setNameError("");
      }
      if (Password === "") {
        setPasswordError("Required");
      } else {
        setPasswordError("");
      }
      if (Cnfemail === "") {
        setEmailError("Required");
      } else {
        setEmailError("");
      }
      if (mobileCnf.length !== 10) {
        setMobileError("Required");
      } else {
        setMobileError("");
      }
    }
  }, [btnPress, name, Password, Cnfemail, mobileCnf]);

  const setNumberChange = async (e) => {
    const value = e.target.value.replace(/\D/g, "");
    setMobileError("");
    setMobile(value);
    if (value.length === 10) {
      const verifyNumber = await dispatch(valiadteNumber(value));
      if (verifyNumber.payload.success) {
        setMobileError("Number already exist");
      } else {
        setMobileError("");
        setMobileCnf(value);
      }
    } else {
      setMobileError("Please Enter Valid Number");
    }
  };

  const emailChange = async (e) => {
    const value = e;
    const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    setEmail(value);
    setEmailError("");
    if (value.length > 0) {
      if (value.match(mailformat)) {
        const verifyEmail = await dispatch(valiadteEmail(value));
        if (verifyEmail.payload.success) {
          setEmailError("Number already exist");
        } else {
          setEmailError("");
          setCnfEmail(value);
        }
      } else {
        setEmailError("please enter correct Email format");
      }
    } else {
      setEmailError("please enter correct Email format");
    }
  };

  const handlesubmit = async (e) => {
    e.preventDefault();
    setbtnLoading(true);
    setbtnPress(true);
    if (name === "") {
      setNameError("Required");
    } else {
      setNameError("");
    }
    if (Password === "") {
      setPasswordError("Required");
    } else {
      setPasswordError("");
    }
    if (Cnfemail === "") {
      setEmailError("Required");
    } else {
      setEmailError("");
    }
    if (mobileCnf.length !== 10) {
      setMobileError("Required");
    } else {
      setMobileError("");
    }
    if (name !== "" && Password !== "" && Cnfemail !== "" && mobile !== "") {
      messageApi.open({
        type: "info",
        content: "Your Request has been sent to server wait for responce",
      });
      const formData = {
        name: name,
        password: Password,
        WebsiteManagement: WebsiteManagement,
        UniversityManagement: UniversityManagement,
        TestManagement: TestManagement,
        StudentManagent: StudentManagent,
        email: Cnfemail,
        mobile: mobile,
      };
      const blog = await dispatch(adminPost(formData));
      if (blog.payload.success) {
        messageApi.open({
          type: "success",
          content: "Admin created successfully",
        });
        setEmail("");
        setCnfEmail("");
        setMobile("");
        setMobileCnf("");
        setName("");
        setPassword("");
        setWebsiteManagement(false);
        setUniversityManagement(false);
        setTestManagement(false);
        setStudentManagent(false);
        setbtnLoading(false);
        setbtnPress(false);
      }
    } else {
      setbtnPress(true);
      setbtnLoading(false);
    }
  };
  const LoadingButton = styled(Button)(({ theme }) => ({
    position: "relative",
  }));

  const LoadingIndicator = styled(CircularProgress)(({ theme }) => ({
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  }));
  return (
    <>
      {contextHolder}
      <div className="main_fragnent_container p-2">
        <div className="profile_container_header">
          <div className="profile_co_header_left">
            <span>Add Role Admin</span>
            <span
              style={{
                visibility: "hidden",
              }}
            >
              Student
            </span>
          </div>
          <div className="profile_co_header_right"></div>
        </div>
        <div className="main_recha_container">
          <div className="inputs_container">
            <div className="dn_input_box">
              <TextField
                label="Name"
                type="text"
                id="text"
                className="input_style"
                error={nameError !== "" ? true : false}
                helperText={nameError}
                value={name}
                onChange={(e) => setName(e.target.value.trim())}
                autocomplete="off"
                size="small"
              />
            </div>
            <div className="dn_input_box">
              <TextField
                label="Phone"
                type="text"
                id="text"
                className="input_style"
                error={mobileError !== "" ? true : false}
                helperText={mobileError}
                value={mobile}
                onChange={(e) => setNumberChange(e)}
                autocomplete="off"
                size="small"
                inputProps={{
                  minLength: 10,
                  maxLength: 10,
                }}
              />
            </div>
            <div className="dn_input_box">
              <TextField
                label="Email"
                type="email"
                id="text"
                className="input_style"
                error={emailError !== "" ? true : false}
                helperText={emailError}
                value={email}
                onChange={(e) => emailChange(e.target.value)}
                autocomplete="off"
                size="small"
              />
            </div>
            <div className="dn_input_box">
              <TextField
                label="Password"
                type="text"
                id="text"
                className="input_style"
                error={PasswordError !== "" ? true : false}
                helperText={PasswordError}
                value={Password}
                onChange={(e) => setPassword(e.target.value.trim())}
                autocomplete="off"
                size="small"
              />
            </div>
            <div className="main_heading_side">
              <span>Role Assign</span>
            </div>
            <div className="dn_input_box">
              <div className="input_style ">
                <h6>Website Management</h6>
                <div className="checkbox-wrapper-6">
                  <input
                    className="tgl tgl-light"
                    id="cb1-6"
                    type="checkbox"
                    checked={WebsiteManagement === true}
                    onChange={(e) => {
                      setWebsiteManagement(e.target.checked);
                    }}
                  />
                  <label className="tgl-btn" htmlFor="cb1-6"></label>
                </div>
              </div>
            </div>
            <div className="dn_input_box">
              <div className="input_style ">
                <h6>Student Management</h6>
                <div className="checkbox-wrapper-6">
                  <input
                    className="tgl tgl-light"
                    id="cb1-7"
                    type="checkbox"
                    checked={StudentManagent === true}
                    onChange={(e) => {
                      setStudentManagent(e.target.checked);
                    }}
                  />
                  <label className="tgl-btn" htmlFor="cb1-7"></label>
                </div>
              </div>
            </div>
            <div className="dn_input_box">
              <div className="input_style ">
                <h6>Test Management</h6>
                <div className="checkbox-wrapper-6">
                  <input
                    className="tgl tgl-light"
                    id="cb1-9"
                    type="checkbox"
                    checked={TestManagement === true}
                    onChange={(e) => {
                      setTestManagement(e.target.checked);
                    }}
                  />
                  <label className="tgl-btn" htmlFor="cb1-9"></label>
                </div>
              </div>
            </div>
            <div className="dn_input_box">
              <div className="input_style ">
                <h6>University Management</h6>
                <div className="checkbox-wrapper-6">
                  <input
                    className="tgl tgl-light"
                    id="cb1-8"
                    type="checkbox"
                    checked={UniversityManagement === true}
                    onChange={(e) => {
                      setUniversityManagement(e.target.checked);
                    }}
                  />
                  <label className="tgl-btn" htmlFor="cb1-8"></label>
                </div>
              </div>
            </div>
            <div className="button_container">
              <LoadingButton
                variant="contained"
                color="primary"
                onClick={(e) => handlesubmit(e)}
                disabled={btnLoading}
              >
                {btnLoading ? (
                  <>
                    LOading
                    <LoadingIndicator size={24} />
                  </>
                ) : (
                  "Submit"
                )}
              </LoadingButton>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Add_Admin;
