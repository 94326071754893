import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { json } from "react-router-dom";
import axios from "axios";
import { act } from "react-dom/test-utils";
const Baseurl = process.env.REACT_APP_BASE_URL
const initialState = {
    totalUniversity: [],
    UniversityLoading: true,
};

export const universityPost = createAsyncThunk(
    "university/universityPost",
    async (formData, thunkAPI) => {
        try {
            const config = {
                Headers: {
                    "content-type": "application/json",
                },
            };
            const url = `${Baseurl}/api/v1/university/new`;
            const resp = await axios.post(url, formData, config);

            return resp.data;
        } catch (error) {
            return thunkAPI.rejectWithValue("university not able to upload");
        }
    }
);
export const majorSubjectPost = createAsyncThunk(
    "university/majorSubjectPost",
    async (formData, thunkAPI) => {
        try {
            const config = {
                Headers: {
                    "content-type": "application/json",
                },
            };
            const url = `${Baseurl}/api/v1/majorsubjects/new`;
            const resp = await axios.post(url, formData, config);

            return resp.data;
        } catch (error) {
            return thunkAPI.rejectWithValue("majorsubjects not able to upload");
        }
    }
);

export const getAlluniversity = createAsyncThunk(
    "university/getAlluniversity",
    async (number, thunkAPI) => {
        try {
            const url = `${Baseurl}/api/v1/university/all`;
            const resp = await axios.get(url);
            return resp.data;
        } catch (error) {
            return error;
        }
    }
);

export const updateuniversity = createAsyncThunk(
    "university/updateuniversity",
    async (formData, thunkAPI) => {
        try {
            const config = {
                Headers: {
                    "content-type": "application/json",
                },
            };
            const url = `${Baseurl}/api/v1/university/update-university/${formData._id}`;
            const resp = await axios.put(url, formData, config);
            return resp.data;
        } catch (error) {
            return thunkAPI.rejectWithValue("auth not able to update");
        }
    }
);


export const valiadteSlugurl = createAsyncThunk(
    "university/valiadteSlugurl",
    async (slugUrl, thunkAPI) => {
        try {
            const url = `${Baseurl}/api/v1/university/university-UNITID/${slugUrl}`;
            const resp = await axios.get(url);
            return resp.data;
        } catch (error) {
            return error;
        }
    }
);


const universitySlice = createSlice({
    name: "university",
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAlluniversity.pending, (state) => {
                state.UniversityLoading = true;
            })
            .addCase(getAlluniversity.fulfilled, (state, action) => {
                if (action.payload.success) {
                    state.totalUniversity = action.payload.university
                    state.totalUniversity = state.totalUniversity.sort((a, b) => (a.universityName > b.universityName ? 1 : -1))
                }
                state.UniversityLoading = false;
            })
            .addCase(getAlluniversity.rejected, (state) => {
                state.UniversityLoading = true;
            })
            .addCase(universityPost.pending, (state) => {
                state.UniversityLoading = true;
            })
            .addCase(universityPost.fulfilled, (state, action) => {
                if (action.payload.success) {
                    state.totalUniversity = [action.payload.university, ...state.totalUniversity];
                    state.totalUniversity = state.totalUniversity.sort((a, b) => (a.universityName > b.universityName ? 1 : -1))
                }
                state.UniversityLoading = false;
            })
            .addCase(universityPost.rejected, (state) => {
                state.UniversityLoading = true;
            })

            .addCase(updateuniversity.pending, (state) => {
                state.UniversityLoading = true;
            })
            .addCase(updateuniversity.fulfilled, (state, action) => {
                if (action.payload.success) {
                    state.totalUniversity = state.totalUniversity.filter(
                        (test) => test._id !== action.payload.university._id
                    );
                    state.totalUniversity = [action.payload.university, ...state.totalUniversity];
                    state.totalUniversity = state.totalUniversity.sort((a, b) => (a.universityName > b.universityName ? 1 : -1))
                }
                state.UniversityLoading = false;
            })
            .addCase(updateuniversity.rejected, (state) => {
                state.UniversityLoading = true;
            })
    },
});

export const {
} = universitySlice.actions;
export default universitySlice.reducer;