import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import axios from "axios";
const Baseurl = process.env.REACT_APP_BASE_URL

const initialState = {
  totalBlogs: [],
  blogSlider: [],
  blogThumbnail: "",
  blogIcon: "",
  isblogSliderLoading: true,
  isblogThumbnailLoading: true,
  isblogIconLoading: true,

  blogLoading: true,
  checkslugUrl: true,
};

export const blogPost = createAsyncThunk(
  "blog/blogpost",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: {
          "content-type": "application/json",
        },
      };
      const url = `${Baseurl}/api/v1/blog/new`;
      const resp = await axios.post(url, formData, config);
    
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("Blog not able to upload");
    }
  }
);
export const valiadteSlugurl = createAsyncThunk(
  "blog/valiadteSlugurl",
  async (slugUrl, thunkAPI) => {
    try {
      const url = `${Baseurl}/api/v1/blog/blogslugurl/${slugUrl}`;
      const resp = await axios.get(url);
      return resp.data;
    } catch (error) {
      return error;
    }
  }
);
export const getAllPost = createAsyncThunk("blog/allBlog", async (thunkAPI) => {
  try {
    const url = `${Baseurl}/api/v1/blog/all`;
    const resp = await axios(url);
    return resp.data;
  } catch (error) {
    return thunkAPI.rejectWithValue("404 Not Found");
  }
});
export const updateBlog = createAsyncThunk(
  "blog/updateBlog",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: {
          "content-type": "application/json",
        },
      };
      const url = `${Baseurl}/api/v1/blog/updateblog/${formData._id}`;
      const resp = await axios.put(url, formData, config);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("Blog not able to update");
    }
  }
);
export const deleteBlog = createAsyncThunk(
  "blod/deleteBlog",
  async (individualBlog, thunkAPI) => {
    try {
      const url = `${Baseurl}/api/v1/blog/deleteblog/${individualBlog}`;
      const resp = await axios.delete(url);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("Blog not able to delete");
    }
  }
);
export const blogThumbnailUpload = createAsyncThunk(
  "blog/blogThumbnail",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
        maxBodyLength: Infinity,
      };
      const url = `${Baseurl}/api/v1/blog/uploadthumbnail`;
      const resp = await axios.post(url, formData, config);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("Blog image not able to  upload");
    }
  }
);
export const blogSliderUpload = createAsyncThunk(
  "blog/blogSlider",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
        maxBodyLength: Infinity,
      };
      const url = `${Baseurl}/api/v1/blog/updateslider`;
      const resp = await axios.post(url, formData, config);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("Slider not able to  upload");
    }
  }
);
export const blogIconUpload = createAsyncThunk(
  "blog/blogIcon",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
        maxBodyLength: Infinity,
      };
      const url = `${Baseurl}/api/v1/blog/updateicon`;

      const resp = await axios.post(url, formData, config);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("Icon not able to  upload");
    }
  }
);

const blogSlice = createSlice({
  name: "blog",
  initialState,
  reducers: {
    resestBlogImage(state) {
      state.blogSlider = [];
      state.blogThumbnail = "";
      state.blogIcon = "";
      state.isblogSliderLoading = true;
      state.isblogThumbnailLoading = true;
      state.isblogIconLoading = true;
    },
    updateBlogThumbImages(state, action) {
      state.blogThumbnail = action.payload;
      state.isblogThumbnailLoading = false;
    },
    updateBlogiconImages(state, action) {
      state.blogIcon = action.payload;
      state.isblogIconLoading = false;
    },
    updateBlogSliderImages(state, action) {
      state.blogSlider = action.payload;
      state.isblogSliderLoading = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(blogPost.pending, (state) => {
        state.blogLoading = true;
      })
      .addCase(blogPost.fulfilled, (state, action) => {
        if (action.payload.success) {
          state.totalBlogs = [action.payload.blog, ...state.totalBlogs];
          localStorage.setItem("totalBlogs", JSON.stringify(state.totalBlogs));
        }
        state.blogLoading = false;
        state.checkslugUrl = false;
      })
      .addCase(blogPost.rejected, (state) => {
        state.blogLoading = true;
      })
      .addCase(getAllPost.pending, (state) => {
        state.blogLoading = true;
      })
      .addCase(getAllPost.fulfilled, (state, action) => {
        if (action.payload.success) {
          state.totalBlogs = action.payload.blogs;
        }
        state.blogLoading = false;
      })
      .addCase(getAllPost.rejected, (state) => {
        state.blogLoading = true;
      })
      .addCase(updateBlog.pending, (state) => {
        state.blogLoading = true;
      })
      .addCase(updateBlog.fulfilled, (state, action) => {
        if (action.payload.success) {
          state.totalBlogs = state.totalBlogs.filter(
            (blog) => blog._id !== action.payload.blog._id
          );
          state.totalBlogs = [action.payload.blog, ...state.totalBlogs];
        }
        localStorage.setItem("totalBlogs", JSON.stringify(state.totalBlogs));
        state.blogLoading = false;
      })
      .addCase(updateBlog.rejected, (state) => {
        state.blogLoading = true;
      })
      .addCase(deleteBlog.pending, (state) => {
        state.blogLoading = true;
      })
      .addCase(deleteBlog.fulfilled, (state, action) => {
        if (action.payload.success) {
          state.totalBlogs = state.totalBlogs.filter(
            (blog) => blog._id !== action.payload.blog._id
          );
          state.totalBlogs = [...state.totalBlogs]
        }
        localStorage.setItem("totalBlogs", JSON.stringify(state.totalBlogs));
        state.blogLoading = false;
      })
      .addCase(deleteBlog.rejected, (state) => {
        state.blogLoading = true;
      })
      .addCase(blogThumbnailUpload.pending, (state) => {
        state.isblogThumbnailLoading = true;
      })
      .addCase(blogThumbnailUpload.fulfilled, (state, action) => {
        if (action.payload.success) {
          state.blogThumbnail = action.payload.thumbnails;
        }
        state.isblogThumbnailLoading = false;
      })
      .addCase(blogThumbnailUpload.rejected, (state) => {
        state.isblogThumbnailLoading = true;
      })
      .addCase(blogSliderUpload.pending, (state) => {
        state.isblogSliderLoading = true;
      })
      .addCase(blogSliderUpload.fulfilled, (state, action) => {
      
        if (action.payload.success) {
          state.blogSlider = [...state.blogSlider, action.payload.sliders];
        }
        state.isblogSliderLoading = false;
      })
      .addCase(blogSliderUpload.rejected, (state) => {
        state.isblogSliderLoading = true;
      })
      .addCase(blogIconUpload.pending, (state) => {
        state.isblogIconLoading = true;
      })
      .addCase(blogIconUpload.fulfilled, (state, action) => {
        if (action.payload.success) {
          state.blogIcon = action.payload.icons;
        }
        state.isblogIconLoading = false;
      })
      .addCase(blogIconUpload.rejected, (state) => {
        state.isblogIconLoading = true;
      })
  },
});
export const {
  updateBlogThumbImages,
  updateBlogiconImages,
  updateBlogSliderImages,
  resestBlogImage,
} = blogSlice.actions;
export default blogSlice.reducer;
