
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import "./App.css";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Login from './pages/Login';
import Dashboard_Layout from './components/dashboard/Dashboard_Layout';
import Dashboard from './pages/Dashboard';
import Registered_Students from "./pages/student/Registered_Students";
import Payment_Student from "./pages/student/Payment_Student";
import List_Program from "./pages/university_management/program/List_Program";

import { getAllPost } from "./redux/Blogs/BlogSlice";
import { getAllAdmins } from "./redux/Authentication/AuthSlice";
import { getAllnotifications } from "./redux/Notifications/Notificationslice";
import { getAlltest } from "./redux/TestManagement/TextManagementSlice";
import { getAlluniversity } from "./redux/university/UniversitySlice";
import { getAllMajor_subjects, getAllProgram } from "./redux/Program/ProgramSlice";
import Add_program from "./pages/university_management/program/Add_program";
import Update_Program from "./pages/university_management/program/Update_Program";
import Search_University from "./pages/university_management/university/Search_University";
import Search_Test from "./pages/test_management/Search_Test";
import Search_Blogs from "./pages/website_management/blogs/Search_Blogs";
import Add_Admin from "./pages/admin_management/Add_Admin";
import Update_admin from "./pages/admin_management/Update_admin";
import Search_admin from "./pages/admin_management/Search_admin";
import Add_Blogs from "./pages/website_management/blogs/Add_Blogs";
import Update_Blog from "./pages/website_management/blogs/Update_Blog";
import AddTest from "./pages/test_management/AddTest";
import Update_Test from "./pages/test_management/Update_Test";
import Add_University from "./pages/university_management/university/Add_University";
import Update_University from "./pages/university_management/university/Update_University";
import Add_MajorSubjects from "./pages/Add_MajorSubjects";
import AddBulkUploade from "./pages/university_management/university/AddBulkUploade";
import AddBulkProgram from "./pages/university_management/program/AddBulkProgram";
import Student_Notifications from "./pages/website_management/notifications/Student_Notifications";
import University_Notifications from "./pages/website_management/notifications/University_Notifications";
import { getAllPaymentStudent, getAllRegisteredStudent } from "./redux/student/StudentSlice";
import PendingTest from "./pages/test_management/PendingTest";
import { getAlltestpaper } from "./redux/testPaper/TestPaperSlice";
import AddTestPaper from "./pages/test_management/AddTestPaper";
import AvailableTestPapers from "./pages/test_management/AvailableTestPapers";
import StudentInfo from "./pages/StudentInfo";
import GradeReport from "./pages/test_management/GradeReport";
import NotificationsByStudentId from "./pages/website_management/notifications/NotificationsByStudentId";
import NotificationsByUniversity from "./pages/website_management/notifications/NotificationsByUniversity";
import ParnterUniversity from "./pages/website_management/ParnterUniversity";

function ScrollToTop() {
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  const {
    major_subjects,
    isMajor_subjectsAvailable,
    major_subjectsLoading,
    totalPrograms,
    isProgramAvailable,
    programLoading,
  } = useSelector((state) => state.porgrams);

  useEffect(() => {
    if (!isMajor_subjectsAvailable || major_subjects.length === 0) {
      dispatch(getAllMajor_subjects());
    }
    if (!isProgramAvailable || totalPrograms.length === 0) {
      dispatch(getAllProgram());
    }
  }, [])

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getAllRegisteredStudent());
    dispatch(getAllPaymentStudent());
    //getBlogList
    dispatch(getAllPost());
    //getAllAdmins
    dispatch(getAllAdmins());
    //getAllnotifications
    dispatch(getAllnotifications());
    //getAllnotifications
    dispatch(getAlltest());
    dispatch(getAlltestpaper());
    //getAlluniversity
    dispatch(getAlluniversity());
    if (major_subjectsLoading) {
      dispatch(getAllMajor_subjects());
    }
    if (programLoading) {
      //getAlluniversity
      dispatch(getAllProgram());
    }

  }, [pathname]);
  return null;
}

function App() {
  return (
    <>
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          <Route
            path="/"
            element={<Login />}
          />
          <Route path="/admin" exact element={<Dashboard_Layout />}>
            <Route path="/admin" index element={<Dashboard />} />
            <Route path="/admin/registered-student" element={<Registered_Students />} />
            <Route path="/admin/payment-student" element={<Payment_Student />} />
            <Route path="/admin/student-info" element={<StudentInfo />} />

            <Route path="/admin/search-program" element={<List_Program />} />
            <Route path="/admin/add-program" element={<Add_program />} />
            <Route path="/admin/add-bulk-program" element={<AddBulkProgram />} />
            <Route path="/admin/update-program/:programId" element={<Update_Program />} />


            <Route path="/admin/add-university" element={<Add_University />} />
            <Route path="/admin/add-bulk-university" element={<AddBulkUploade />} />
            <Route path="/admin/search-university" element={<Search_University />} />
            <Route path="/admin/update-university/:_id" element={<Update_University />} />

            <Route path="/admin/add-test" element={<AddTest />} />
            <Route path="/admin/add-test-paper" element={<AddTestPaper />} />
            <Route path="/admin/search-test" element={<Search_Test />} />
            <Route path="/admin/search-test-paper" element={<AvailableTestPapers />} />
            <Route path="/admin/pending-test" element={<PendingTest />} />
            <Route path="/admin/update-test/:_id" element={<Update_Test />} />
            <Route path="/admin/grade-report" element={<GradeReport />} />

            <Route path="/admin/add-blogs" element={<Add_Blogs />} />
            <Route path="/admin/search-blogs" element={<Search_Blogs />} />
            <Route path="/admin/update-blogs/:_id" element={<Update_Blog />} />

            <Route path="/admin/add-admin" element={<Add_Admin />} />
            <Route path="/admin/search-admin" element={<Search_admin />} />
            <Route path="/admin/update-admin/:_id" element={<Update_admin />} />

            <Route path="/admin/student-notifications" element={<Student_Notifications />} />
            <Route path="/admin/student-id-notifications/:id" element={<NotificationsByStudentId />} />
            <Route path="/admin/university-notifications" element={<University_Notifications />} />
            <Route path="/admin/university-id-notifications/:id" element={<NotificationsByUniversity />} />
            <Route path="/admin/partner-university" element={<ParnterUniversity />} />

            <Route path="/admin/add-majorsubjects" element={<Add_MajorSubjects />} />


          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
