
import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import MenuItem from "@mui/material/MenuItem";
import { TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { PiPaperPlaneRight } from "react-icons/pi";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import CircularProgress from "@mui/material/CircularProgress";
import { Typography, message, DatePicker, Switch } from "antd";
import { valiadteSlugurl } from "../../redux/Blogs/BlogSlice";
import {
  testPost,
  TestThumbnailUpload,
  TestVideoUpload,
  updatetest,
  valiadteTestCode,
} from "../../redux/TestManagement/TextManagementSlice";
import axios from "axios";

const Update_Test = () => {
  const [title, setTitle] = useState("");
  const [slugUrl, setSlugUrl] = useState("");
  const [checkSlugUrl, setCheckSlugUrl] = useState("");
  const [slugUrlError, setSlugUrlError] = useState("");
  const [titleError, seTitleError] = useState("");

  const [degreeName, setDegreeName] = useState("");
  const [examType, setExamType] = useState("");
  const [degreeNameError, setDegreeNameError] = useState("");
  const [examTypeError, setExamTypeError] = useState("");

  const [testCode, setTestCode] = useState("");
  const [testCodeCnf, setTestCodeCnf] = useState("");
  const [testCodeError, setTestCodeError] = useState("");

  const [TestType, setTestType] = useState("");
  const [TestTypeError, setTestTypeError] = useState("");

  const [SubjectArea, setSubjectArea] = useState("");
  const [SubjectAreaError, setSubjectAreaError] = useState("");

  const [TestDateandTime, setTestDateandTime] = useState("");
  const [TestDateandTimeError, setTestDateandTimeError] = useState("");

  const [Duration, setDuration] = useState("");
  const [DurationError, setDurationError] = useState("");

  const [NumberofQuestions, setNumberofQuestions] = useState("");
  const [NumberofQuestionsError, setNumberofQuestionsError] = useState("");

  const [PassingCriteriaoption, setPassingCriteriaoption] = useState([
    { criteria: "" },
  ]);

  const [PassingCriteriaoptionError, setPassingCriteriaoptionError] =
    useState("");
  const [PassingCriteriaoptionIndex, setPassingCriteriaoptionIndex] =
    useState("");
  const [PassingCriteriaoptionBtnPress, setPassingCriteriaoptionBtnPress] =
    useState(false);

  const [TestInstructionsoption, setTestInstructionsoption] = useState([
    { Instructions: "" },
  ]);
  const [TestInstructionsoptionIndex, setTestInstructionsoptionIndex] =
    useState("");
  const [TestInstructionsoptionBtnPress, setTestInstructionsoptionBtnPress] =
    useState(false);

  const [Questionsoption, setQuestionsoption] = useState([
    {
      QuestionTitle: "",
      option1: "",
      option2: "",
      option3: "",
      option4: "",
      correctAnswer: "",
      disclaimer: "",
      image: "",
      Video: "",

      paragraph: "",
      answerExplaination: "",
      qustion_discription: "",
      VideoStatus: false,
      imageStatus: false,
      paragraphStatus: false,
      answerExplainationStatus: false,
      qustion_discriptionStatus: false,
      option_status: false,
    },
  ]);
  const [QuestionsoptionIndex, setQuestionsoptionIndex] = useState("");
  const [QuestionsoptionbtnPress, setQuestionsoptionbtnPress] = useState("");
  const [
    QuestionsoptionQuestionTitleerror,
    setQuestionsoptionQuestionTitleerror,
  ] = useState("");
  const [Questionsoptionoption1error, setQuestionsoptionoption1error] =
    useState("");
  const [Questionsoptionoption2error, setQuestionsoptionoption2error] =
    useState("");
  const [Questionsoptionoption3error, setQuestionsoptionoption3error] =
    useState("");
  const [Questionsoptionoption4error, setQuestionsoptionoption4error] =
    useState("");
  const [correctAnswererror, setcorrectAnswererror] = useState("");

  const [btnPress, setbtnPress] = useState(false);
  const [btnLoading, setbtnLoading] = useState(false);

  const [btnLoadsave, setbtnLoadsave] = useState(false);

  const [messageApi, contextHolder] = message.useMessage();

  const videoRef = useRef(null);
  const params = useParams();
  const handlePlay = () => {
    console.log("Video playing");
  };


  useEffect(() => {
    const fetchData = async () => {
      const url = `${process.env.REACT_APP_BASE_URL}/api/v1/testmanagement/single-test-managementByid/${params._id}`;
      const fetchBlog = await axios.get(url);
      if (fetchBlog.data.success === true) {
        const foundBlog = fetchBlog.data.testManagement;
        setDegreeName(foundBlog.degreeName);
        setExamType(foundBlog.examType);
        setTitle(foundBlog.TestTitle);
        setCheckSlugUrl(foundBlog.slugUrl);
        setTestCode(foundBlog.TestCode);
        setTestCodeCnf(foundBlog.TestCode);
        setTestType(foundBlog.TestType);
        setSubjectArea(foundBlog.SubjectArea);
        setTestDateandTime(foundBlog.TestDateandTime);
        setDuration(foundBlog.Duration);
        setNumberofQuestions(foundBlog.NumberofQuestions);
        setPassingCriteriaoption(foundBlog.PassingCriteria);
        setTestInstructionsoption(foundBlog.TestInstructions);
        setQuestionsoption(foundBlog.Questions);
      }
    };
    fetchData();
  }, [params._id]);

  const handlePause = () => {
    console.log("Video paused");
  };

  const selectTest1 = [
    {
      id: 5,
      name: "TOEFL",
    },
    {
      id: 6,
      name: "IELTS",
    },
    {
      id: 1,
      name: "GMAT",
    },
    {
      id: 2,
      name: "GRE",
    },
    {
      id: 4,
      name: "SAT",
    },
    {
      id: 5,
      name: "ACT",
    },
  ];

  const handleCheckQuestion = async (index, e, selected) => {
    let temp = [...Questionsoption];
    const find_qustion = temp[index];

    if (selected === "image") {
      const files = Array.from(e.target.files);
      files.forEach((file) => {
        const reader = new FileReader();
        reader.onload = async () => {
          if (reader.readyState === 2) {
            const getImage = await dispatch(
              TestThumbnailUpload({ thumbnail: reader.result })
            );
            if (getImage.payload.success === true) {
              const image = getImage.payload.thumbnail;
              temp[index][selected] = image;
            }
          }
        };
        reader.readAsDataURL(file);
      });
    } else if (selected === "option1" && find_qustion.option_status === true) {
      const files = Array.from(e.target.files);
      files.forEach((file) => {
        const reader = new FileReader();
        reader.onload = async () => {
          if (reader.readyState === 2) {
            const getImage = await dispatch(
              TestThumbnailUpload({ thumbnail: reader.result })
            );
            if (getImage.payload.success === true) {
              const image = getImage.payload.thumbnail;
              temp[index][selected] = image;
            }
          }
        };
        reader.readAsDataURL(file);
      });
    } else if (selected === "option2" && find_qustion.option_status === true) {
      const files = Array.from(e.target.files);
      files.forEach((file) => {
        const reader = new FileReader();
        reader.onload = async () => {
          if (reader.readyState === 2) {
            const getImage = await dispatch(
              TestThumbnailUpload({ thumbnail: reader.result })
            );
            if (getImage.payload.success === true) {
              const image = getImage.payload.thumbnail;
              temp[index][selected] = image;
            }
          }
        };
        reader.readAsDataURL(file);
      });
    } else if (selected === "option3" && find_qustion.option_status === true) {
      const files = Array.from(e.target.files);
      files.forEach((file) => {
        const reader = new FileReader();
        reader.onload = async () => {
          if (reader.readyState === 2) {
            const getImage = await dispatch(
              TestThumbnailUpload({ thumbnail: reader.result })
            );
            if (getImage.payload.success === true) {
              const image = getImage.payload.thumbnail;
              temp[index][selected] = image;
            }
          }
        };
        reader.readAsDataURL(file);
      });
    } else if (selected === "option4" && find_qustion.option_status === true) {
      const files = Array.from(e.target.files);
      files.forEach((file) => {
        const reader = new FileReader();
        reader.onload = async () => {
          if (reader.readyState === 2) {
            const getImage = await dispatch(
              TestThumbnailUpload({ thumbnail: reader.result })
            );
            if (getImage.payload.success === true) {
              const image = getImage.payload.thumbnail;
              temp[index][selected] = image;
            }
          }
        };
        reader.readAsDataURL(file);
      });
    } else if (selected === "Video") {
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.onloadend = async () => {
        const base64Video = reader.result;
        const url = `${process.env.REACT_APP_BASE_URL}/api/v1/testmanagement/uploade-video`;
        try {
          const response = await fetch(url, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ video: base64Video }),
          });
          const getVideo = await response.json();
          if (getVideo.success === true) {
            const video = getVideo.video;
            temp[index][selected] = video;
          }
        } catch (error) {
          console.error("Error uploading video:", error);
        }
      };
      reader.readAsDataURL(file);
    } else if (selected === "option_status") {
      temp[index][selected] = e;
      temp[index]["option1"] = "";
      temp[index]["option2"] = "";
      temp[index]["option3"] = "";
      temp[index]["option4"] = "";
    } else if (
      selected === "imageStatus" ||
      selected === "VideoStatus" ||
      selected === "paragraphStatus" ||
      selected === "answerExplainationStatus" ||
      selected === "qustion_discriptionStatus"
    ) {
      temp[index][selected] = e;
    } else {
      temp[index][selected] = e.target.value;
    }
    setQuestionsoption(temp);
  };

  const handleNewRowQuestion = () => {
    const lastIndex = Questionsoption.length - 1;
    setQuestionsoptionIndex("");
    setQuestionsoptionQuestionTitleerror("");
    setQuestionsoptionoption1error("");
    setQuestionsoptionoption2error("");
    setQuestionsoptionoption3error("");
    setQuestionsoptionoption4error("");

    if (Questionsoption[lastIndex].QuestionTitle === "") {
      setQuestionsoptionIndex(lastIndex);
      setQuestionsoptionQuestionTitleerror("error");
      setQuestionsoptionbtnPress(true);
    }
    if (Questionsoption[lastIndex].option1 === "") {
      setQuestionsoptionoption1error("error");
      setQuestionsoptionIndex(lastIndex);
      setQuestionsoptionbtnPress(true);
    }
    if (Questionsoption[lastIndex].option2 === "") {
      setQuestionsoptionoption2error("error");
      setQuestionsoptionIndex(lastIndex);
      setQuestionsoptionbtnPress(true);
    }
    if (Questionsoption[lastIndex].option3 === "") {
      setQuestionsoptionoption3error("error");
      setQuestionsoptionIndex(lastIndex);
      setQuestionsoptionbtnPress(true);
    }
    if (Questionsoption[lastIndex].option4 === "") {
      setQuestionsoptionoption4error("error");
      setQuestionsoptionIndex(lastIndex);
      setQuestionsoptionbtnPress(true);
    }
    if (Questionsoption[lastIndex].correctAnswer === "") {
      setcorrectAnswererror("error");
      setQuestionsoptionIndex(lastIndex);
      setQuestionsoptionbtnPress(true);
    }

    let paragraphstatus = true;
    if (
      Questionsoption[lastIndex].paragraph === "" &&
      Questionsoption[lastIndex].paragraphStatus === true
    ) {
      paragraphstatus = false;
    }
    let answerExplaination = true;
    if (
      Questionsoption[lastIndex].answerExplaination === "" &&
      Questionsoption[lastIndex].answerExplainationStatus === true
    ) {
      answerExplaination = false;
    }
    let qustion_discription = true;
    if (
      Questionsoption[lastIndex].qustion_discription === "" &&
      Questionsoption[lastIndex].qustion_discriptionStatus === true
    ) {
      qustion_discription = false;
    }

    if (
      Questionsoption[lastIndex].QuestionTitle === "" ||
      Questionsoption[lastIndex].option1 === "" ||
      Questionsoption[lastIndex].option2 === "" ||
      Questionsoption[lastIndex].option3 === "" ||
      Questionsoption[lastIndex].correctAnswer === "" ||
      paragraphstatus === false ||
      answerExplaination === false ||
      qustion_discription === false ||
      Questionsoption[lastIndex].option4 === ""
    ) {
      setQuestionsoptionIndex(lastIndex);
      setQuestionsoptionbtnPress(true);
    } else {
      setQuestionsoption([
        ...Questionsoption,
        {
          QuestionTitle: "",
          option1: "",
          option2: "",
          option3: "",
          option4: "",
          correctAnswer: "",
          disclaimer: "",
          image: "",
          Video: "",
          paragraph: "",
          answerExplaination: "",
          qustion_discription: "",
          VideoStatus: false,
          imageStatus: false,
          paragraphStatus: false,
          answerExplainationStatus: false,
          qustion_discriptionStatus: false,
        },
      ]);
    }
  };

  const removeRowClick = (e, crrentindex) => {
    const optionList = [...PassingCriteriaoption];

    const filterdata = optionList.filter(
      (data, index) => index !== crrentindex
    );

    // optionList.splice(index, 1);
    setPassingCriteriaoption([...filterdata]);
  };
  const removeRowClickTestInst = (e, crrentindex) => {
    const optionList = [...TestInstructionsoption];
    const filterdata = optionList.filter(
      (data, index) => index !== crrentindex
    );
    setTestInstructionsoption(filterdata);
  };
  const removeRowClickQuestion = (e, crrentindex) => {
    const optionList = [...Questionsoption];
    const filterdata = optionList.filter(
      (data, index) => index !== crrentindex
    );
    setQuestionsoption(filterdata);
  };

  useEffect(() => {
    if (btnPress === true) {
      if (title === "") {
        seTitleError("please enter title");
      } else {
        seTitleError("");
      }
      if (testCodeCnf === "") {
        setTestCodeError("please enter Test Code");
      } else {
        setTestCodeError("");
      }
      if (TestType === "") {
        setTestTypeError("please enter Test Type");
      } else {
        setTestTypeError("");
      }

      if (SubjectArea === "") {
        setSubjectAreaError("please enter mobile");
      } else {
        setSubjectAreaError("");
      }
      if (TestDateandTime === "") {
        setTestDateandTimeError("please enter mobile");
      } else {
        setTestDateandTimeError("");
      }
      if (Duration === "") {
        setDurationError("please enter Duration");
      } else {
        setDurationError("");
      }
      if (NumberofQuestions === "") {
        setNumberofQuestionsError("please enter No. of Questions");
      } else {
        setNumberofQuestionsError("");
      }
      if (examType === "") {
        setExamTypeError("please select category type");
      } else {
        setExamTypeError("");
      }
      if (degreeName === "") {
        setDegreeNameError("please select degree");
      } else {
        setDegreeNameError("");
      }
    }
    if (PassingCriteriaoptionBtnPress === true) {
      const lastIndex = PassingCriteriaoption.length - 1;
      if (PassingCriteriaoption[lastIndex].criteria === "") {
        setPassingCriteriaoptionError("error");
        setPassingCriteriaoptionIndex(lastIndex);
      } else {
        setPassingCriteriaoptionError("");
        setPassingCriteriaoptionIndex("");
      }
    }
    if (TestInstructionsoptionBtnPress === true) {
      const lastIndex = TestInstructionsoption.length - 1;
      if (TestInstructionsoption[lastIndex].Instructions === "") {
        setTestInstructionsoptionIndex(lastIndex);
      } else {
        setTestInstructionsoptionIndex("");
      }
    }
    if (QuestionsoptionbtnPress === true) {
      const lastIndex = Questionsoption.length - 1;
      setQuestionsoptionIndex("");
      setQuestionsoptionQuestionTitleerror("");
      setQuestionsoptionoption1error("");
      setQuestionsoptionoption2error("");
      setQuestionsoptionoption3error("");
      setQuestionsoptionoption4error("");
      setcorrectAnswererror("");

      if (Questionsoption[lastIndex].QuestionTitle === "") {
        setQuestionsoptionIndex(lastIndex);
        setQuestionsoptionQuestionTitleerror("error");
      } else {
        setQuestionsoptionQuestionTitleerror("");
      }
      if (Questionsoption[lastIndex].option1 === "") {
        setQuestionsoptionoption1error("error");
        setQuestionsoptionIndex(lastIndex);
      } else {
        setQuestionsoptionoption1error("");
      }
      if (Questionsoption[lastIndex].option2 === "") {
        setQuestionsoptionoption2error("error");
        setQuestionsoptionIndex(lastIndex);
      } else {
        setQuestionsoptionoption2error("");
      }
      if (Questionsoption[lastIndex].option3 === "") {
        setQuestionsoptionoption3error("error");
        setQuestionsoptionIndex(lastIndex);
      } else {
        setQuestionsoptionoption3error("");
      }
      if (Questionsoption[lastIndex].option4 === "") {
        setQuestionsoptionoption4error("error");
        setQuestionsoptionIndex(lastIndex);
      } else {
        setQuestionsoptionoption4error("");
      }
      if (Questionsoption[lastIndex].correctAnswer === "") {
        setcorrectAnswererror("error");
        setQuestionsoptionIndex(lastIndex);
        setQuestionsoptionbtnPress(true);
      }
    }
  }, [
    btnPress,
    degreeName,
    examType,
    title,
    testCodeCnf,
    TestType,
    Duration,
    NumberofQuestions,
    SubjectArea,
    TestDateandTime,
    PassingCriteriaoption,
    PassingCriteriaoptionBtnPress,
    TestInstructionsoptionBtnPress,
    TestInstructionsoption,
    QuestionsoptionbtnPress,
    Questionsoption,
  ]);

  const dispatch = useDispatch();

  const handleTitle = async (e) => {
    const value = e.target.value.trim();
    setSlugUrl(value);
    setTitle(value);
    let slug = value
      .trim()
      .toLowerCase()
      .replace(" ", "-")
      .replace(/[.*+&?^ $@#%^!'/{}()|[\]\\]/g, "-")
      .replace("---", "-")
      .replace("----", "-")
      .replace("--", "-");
    setSlugUrl(slug);
    if (slug !== "") {
      let response = await dispatch(valiadteSlugurl(slug));
      if (response.payload.success) {
        setSlugUrlError("name already exist");
        seTitleError("name already exist");
      } else {
        setCheckSlugUrl(slug);
        setSlugUrlError("");
        seTitleError("");
      }
    }
  };

  const handleSubtitle = async (e) => {
    const value = e.target.value;
    setTestCodeError("");
    setTestCode(value);
    if (value !== "") {
      const verifyTestCode = await dispatch(valiadteTestCode(value));
      if (verifyTestCode.payload.success) {
        setTestCodeError("Number already exist");
        setTestCodeCnf("");
      } else {
        setTestCodeError("");
        setTestCodeCnf(value);
      }
    } else {
      setTestCodeError("Please Enter Valid Number");
    }
  };

  const cancelClick = async (e) => {
    e.preventDefault();
    setTitle("");
    setSlugUrl("");
    setCheckSlugUrl("");
    setSlugUrlError("");
    setTestCode("");
    setTestType("");
    setSubjectArea("");
    setTestDateandTime("");
    setDuration("");
    setNumberofQuestions("");
    setPassingCriteriaoption([{ criteria: "" }]);
    setTestInstructionsoption([{ Instructions: "" }]);
    setQuestionsoption([
      {
        QuestionTitle: "",
        option1: "",
        option2: "",
        option3: "",
        option4: "",
        correctAnswer: "",
        disclaimer: "",
        image: "",
        Video: "",
        paragraph: "",
        answerExplaination: "",
        qustion_discription: "",
        VideoStatus: false,
        imageStatus: false,
        paragraphStatus: false,
        answerExplainationStatus: false,
        qustion_discriptionStatus: false,
      },
    ]);
    seTitleError("");
    setTestCodeError("");
    setTestTypeError("");
    setSubjectAreaError("");
    setTestDateandTimeError("");
    setDurationError("");
    setNumberofQuestionsError("");
    setPassingCriteriaoptionError("");
    setPassingCriteriaoptionIndex("");
    setTestInstructionsoptionIndex("");
    setQuestionsoptionIndex("");
    setQuestionsoptionQuestionTitleerror("");
    setQuestionsoptionoption1error("");
    setQuestionsoptionoption2error("");
    setQuestionsoptionoption3error("");
    setQuestionsoptionoption4error("");
    setcorrectAnswererror("");
    setQuestionsoptionbtnPress(false);
    setbtnPress(false);
    setTestInstructionsoptionBtnPress(false);
    setPassingCriteriaoptionBtnPress(false);
  };

  const handlesubmit = async (e) => {
    e.preventDefault();
    setbtnLoading(true);
    if (title === "") {
      seTitleError("please enter title");
    } else {
      seTitleError("");
    }
    if (testCodeCnf === "") {
      setTestCodeError("please enter Test Code");
    } else {
      setTestCodeError("");
    }
    if (TestType === "") {
      setTestTypeError("please enter Test Type");
    } else {
      setTestTypeError("");
    }

    if (SubjectArea === "") {
      setSubjectAreaError("please enter mobile");
    } else {
      setSubjectAreaError("");
    }
    if (TestDateandTime === "") {
      setTestDateandTimeError("please enter mobile");
    } else {
      setTestDateandTimeError("");
    }
    if (Duration === "") {
      setDurationError("please enter Duration");
    } else {
      setDurationError("");
    }
    if (NumberofQuestions === "") {
      setNumberofQuestionsError("please enter No. of Questions");
    } else {
      setNumberofQuestionsError("");
    }
    if (examType === "") {
      setExamTypeError("please select category type");
    } else {
      setExamTypeError("");
    }
    if (degreeName === "") {
      setDegreeNameError("please select degree");
    } else {
      setDegreeNameError("");
    }
    const lastIndex = Questionsoption.length - 1;
    setQuestionsoptionIndex("");
    setQuestionsoptionQuestionTitleerror("");
    setQuestionsoptionoption1error("");
    setQuestionsoptionoption2error("");
    setQuestionsoptionoption3error("");
    setQuestionsoptionoption4error("");

    if (Questionsoption[lastIndex].QuestionTitle === "") {
      setQuestionsoptionIndex(lastIndex);
      setQuestionsoptionQuestionTitleerror("error");
      setQuestionsoptionbtnPress(true);
    } else {
      setQuestionsoptionQuestionTitleerror("");
    }
    if (Questionsoption[lastIndex].option1 === "") {
      setQuestionsoptionoption1error("error");
      setQuestionsoptionIndex(lastIndex);
      setQuestionsoptionbtnPress(true);
    } else {
      setQuestionsoptionoption1error("");
    }
    if (Questionsoption[lastIndex].option2 === "") {
      setQuestionsoptionoption2error("error");
      setQuestionsoptionIndex(lastIndex);
      setQuestionsoptionbtnPress(true);
    } else {
      setQuestionsoptionoption2error("");
    }
    if (Questionsoption[lastIndex].option3 === "") {
      setQuestionsoptionoption3error("error");
      setQuestionsoptionIndex(lastIndex);
      setQuestionsoptionbtnPress(true);
    } else {
      setQuestionsoptionoption3error("");
    }
    if (Questionsoption[lastIndex].option4 === "") {
      setQuestionsoptionoption4error("error");
      setQuestionsoptionIndex(lastIndex);
      setQuestionsoptionbtnPress(true);
    } else {
      setQuestionsoptionoption4error("");
    }

    if (Questionsoption[lastIndex].correctAnswer === "") {
      setcorrectAnswererror("error");
      setQuestionsoptionIndex(lastIndex);
      setQuestionsoptionbtnPress(true);
    } else {
      setcorrectAnswererror("");
    }

    const lastIndexInst = TestInstructionsoption.length - 1;
    if (TestInstructionsoption[lastIndexInst].Instructions === "") {
      setTestInstructionsoptionIndex(lastIndexInst);
    } else {
      setTestInstructionsoptionIndex("");
    }

    const lastIndexPass = PassingCriteriaoption.length - 1;
    if (PassingCriteriaoption[lastIndexPass].criteria === "") {
      setPassingCriteriaoptionError("error");
      setPassingCriteriaoptionIndex(lastIndexPass);
    } else {
      setPassingCriteriaoptionError("");
      setPassingCriteriaoptionIndex("");
    }

    let paragraphstatus = true;
    if (
      Questionsoption[lastIndex].paragraph === "" &&
      Questionsoption[lastIndex].paragraphStatus === true
    ) {
      paragraphstatus = false;
    }
    let answerExplaination = true;
    if (
      Questionsoption[lastIndex].answerExplaination === "" &&
      Questionsoption[lastIndex].answerExplainationStatus === true
    ) {
      answerExplaination = false;
    }
    let qustion_discription = true;
    if (
      Questionsoption[lastIndex].qustion_discription === "" &&
      Questionsoption[lastIndex].qustion_discriptionStatus === true
    ) {
      qustion_discription = false;
    }

    if (
      title !== "" &&
      testCodeCnf !== "" &&
      examType !== "" &&
      TestType !== "" &&
      Duration !== "" &&
      NumberofQuestions !== "" &&
      NumberofQuestions.length > 0 &&
      Questionsoption.length > 0 &&
      Questionsoption[lastIndex].QuestionTitle !== "" &&
      Questionsoption[lastIndex].option1 !== "" &&
      Questionsoption[lastIndex].option2 !== "" &&
      Questionsoption[lastIndex].option3 !== "" &&
      Questionsoption[lastIndex].option4 !== "" &&
      // Questionsoption[lastIndex].correctAnswer !== "" &&
      paragraphstatus === true &&
      answerExplaination === true &&
      qustion_discription === true
    ) {
      const formData = {
        TestTitle: title,
        slugUrl: checkSlugUrl,
        TestCode: testCodeCnf,
        TestType: TestType,
        examType: examType,
        SubjectArea: SubjectArea,
        TestDateandTime: TestDateandTime,
        Duration: Duration,
        NumberofQuestions: NumberofQuestions,
        PassingCriteria: PassingCriteriaoption,
        TestInstructions: TestInstructionsoption,
        Questions: Questionsoption,
        degreeName: degreeName,
        status: true,
        _id: params._id,
      };

      const blog = await dispatch(updatetest(formData));
      if (blog.payload.success) {
        messageApi.open({
          type: "success",
          content: "Test Updated successfully",
        });
        setbtnPress(false);
        setQuestionsoptionbtnPress(false);
        setTestInstructionsoptionBtnPress(false);
        setPassingCriteriaoptionBtnPress(false);
      }
      setbtnLoading(false);
    } else {
      setbtnPress(true);
      setQuestionsoptionbtnPress(true);
      setTestInstructionsoptionBtnPress(true);
      setPassingCriteriaoptionBtnPress(true);
      setbtnLoading(false);
    }
  };
  const handleSavesubmit = async (e) => {
    e.preventDefault();
    setbtnLoadsave(true);
    if (title === "") {
      seTitleError("please enter title");
    } else {
      seTitleError("");
    }
    if (testCodeCnf === "") {
      setTestCodeError("please enter Test Code");
    } else {
      setTestCodeError("");
    }
    if (title !== "" && testCodeCnf !== "") {
      const formData = {
        TestTitle: title,
        slugUrl: checkSlugUrl,
        TestCode: testCodeCnf,
        TestType: TestType,
        examType: examType,
        SubjectArea: SubjectArea,
        TestDateandTime: TestDateandTime,
        Duration: Duration,
        NumberofQuestions: NumberofQuestions,
        PassingCriteria: PassingCriteriaoption,
        TestInstructions: TestInstructionsoption,
        Questions: Questionsoption,
        degreeName: degreeName,
        status: false,
        _id: params._id,
      };

      const blog = await dispatch(updatetest(formData));
      if (blog.payload.success === true) {
        messageApi.open({
          type: "success",
          content: "Test Save successfully",
        });
        setbtnPress(false);
        setbtnLoadsave(false);
      } else {
        setbtnLoadsave(false);
      }
      setbtnLoadsave(false);
    } else {
      setbtnLoadsave(false);
    }
  };

  const LoadingButton = styled(Button)(({ theme }) => ({
    position: "relative",
  }));

  const LoadingIndicator = styled(CircularProgress)(({ theme }) => ({
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  }));

  return (
    <>
      {contextHolder}
      <div className="main_fragnent_container p-2">
        <div className="profile_container_header">
          <div className="profile_co_header_left">
            <span>Add Test Details</span>
            <span
              style={{
                visibility: "hidden",
              }}
            >
              Student
            </span>
          </div>
          <div className="profile_co_header_right"></div>
        </div>
        <div className="main_recha_container">
          <div className="inputs_container">
            <div className="dn_input_box">
              <TextField
                id="select"
                label="Test Category"
                select
                className="input_style"
                error={examTypeError !== "" ? true : false}
                helperText={examTypeError}
                value={examType}
                onChange={(e) => {
                  setExamType(e.target.value);
                }}
                size="small"
              >
                {selectTest1 &&
                  selectTest1.map((data, index) => (
                    <MenuItem key={index} value={data.name}>
                      {data.name}
                    </MenuItem>
                  ))}
              </TextField>
            </div>
            <div className="dn_input_box">
              <TextField
                label="Test Title"
                type="text"
                id="text"
                className="input_style"
                error={titleError !== "" ? true : false}
                helperText={titleError}
                value={title}
                onChange={(e) => handleTitle(e)}
                autocomplete="off"
                size="small"
              />
            </div>
            <div className="dn_input_box">
              <TextField
                label="Test Code"
                type="text"
                id="text"
                className="input_style"
                error={testCodeError !== "" ? true : false}
                helperText={testCodeError}
                value={testCode}
                onChange={(e) => handleSubtitle(e)}
                autocomplete="off"
                size="small"
              />
            </div>
            <div className="dn_input_box">
              <TextField
                label="Test Type"
                type="text"
                id="text"
                className="input_style"
                error={TestTypeError !== "" ? true : false}
                helperText={TestTypeError}
                value={TestType}
                onChange={(e) => setTestType(e.target.value)}
                autocomplete="off"
                size="small"
              />
            </div>

            <div className="dn_input_box">
              <TextField
                label="Duration (Enters in Minitues Only)"
                type="text"
                id="text"
                className="input_style"
                error={DurationError !== "" ? true : false}
                helperText={DurationError}
                value={Duration}
                onChange={(e) => setDuration(e.target.value.replace(/\D/g, ""))}
                autocomplete="off"
                size="small"
              />
            </div>
            <div className="dn_input_box">
              <TextField
                label="Number of Questions"
                type="text"
                id="text"
                className="input_style"
                error={NumberofQuestionsError !== "" ? true : false}
                helperText={NumberofQuestionsError}
                value={NumberofQuestions}
                onChange={(e) =>
                  setNumberofQuestions(e.target.value.replace(/\D/g, ""))
                }
                autocomplete="off"
                size="small"
              />
            </div>
            <div className="dn_input_box">
              <div className="input_style"></div>
            </div>

            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                width: "100%",
              }}
            >
              {Questionsoption.map((question, index) => (
                <>
                  <div className="qusti_status_container">
                    <div className="swich_box">
                      <h6>Image</h6>
                      <Switch
                        checked={question?.imageStatus}
                        onChange={(e) =>
                          handleCheckQuestion(index, e, "imageStatus")
                        }
                      />
                    </div>
                    <div className="swich_box">
                      <h6>Video</h6>
                      <Switch
                        checked={question?.VideoStatus}
                        onChange={(e) =>
                          handleCheckQuestion(index, e, "VideoStatus")
                        }
                      />
                    </div>
                    <div className="swich_box ans_swit">
                      <h6>Answer Explaination</h6>
                      <Switch
                        checked={question?.answerExplainationStatus}
                        onChange={(e) =>
                          handleCheckQuestion(
                            index,
                            e,
                            "answerExplainationStatus"
                          )
                        }
                      />
                    </div>
                    <div className="swich_box">
                      <h6>Paragraph</h6>
                      <Switch
                        checked={question?.paragraphStatus}
                        onChange={(e) =>
                          handleCheckQuestion(index, e, "paragraphStatus")
                        }
                      />
                    </div>
                    <div className="swich_box">
                      <h6>Description</h6>
                      <Switch
                        checked={question?.qustion_discriptionStatus}
                        onChange={(e) =>
                          handleCheckQuestion(
                            index,
                            e,
                            "qustion_discriptionStatus"
                          )
                        }
                      />
                    </div>
                    <div className="swich_box">
                      <h6>Option Type</h6>
                      <Switch
                        checked={question?.option_status}
                        onChange={(e) =>
                          handleCheckQuestion(index, e, "option_status")
                        }
                      />
                    </div>
                  </div>
                  {question?.paragraphStatus === true && (
                    <>
                      <div className="success_input_box">
                        <TextField
                          label={`Paragraph`}
                          style={{
                            width: "98%",
                          }}
                          error={
                            QuestionsoptionbtnPress === true &&
                            question?.paragraph === "" &&
                            question?.paragraphStatus === true &&
                            QuestionsoptionIndex === index
                              ? true
                              : false
                          }
                          helperText={
                            QuestionsoptionbtnPress === true &&
                            question?.paragraph === "" &&
                            question?.paragraphStatus === true &&
                            QuestionsoptionIndex === index
                              ? "Required"
                              : ""
                          }
                          value={question?.paragraph}
                          onChange={(e) =>
                            handleCheckQuestion(index, e, "paragraph")
                          }
                          autocomplete="off"
                          id="outlined-multiline-static"
                          multiline
                          rows={4}
                        />
                      </div>
                      <div className="dn_input_box mt-3"></div>
                    </>
                  )}
                  <div className="success_input_box">
                    <TextField
                      label={`Question ${index + 1}`}
                      style={{
                        width: "98%",
                      }}
                      error={
                        QuestionsoptionQuestionTitleerror !== "" &&
                        QuestionsoptionIndex === index
                          ? true
                          : false
                      }
                      helperText={
                        QuestionsoptionQuestionTitleerror !== "" &&
                        QuestionsoptionIndex === index
                          ? "Required"
                          : ""
                      }
                      value={question?.QuestionTitle}
                      onChange={(e) =>
                        handleCheckQuestion(index, e, "QuestionTitle")
                      }
                      autocomplete="off"
                      id="outlined-multiline-static"
                      multiline
                      rows={4}
                    />
                  </div>
                  <div className="dn_input_box mt-3"></div>
                  <div className="dn_input_box mt-3"></div>
                  {question?.option_status === true ? (
                    <>
                      <div className="dn_input_box">
                        <div className="input_style">
                          <h6>Option A</h6>
                          <input
                            type="file"
                            id="img"
                            name="img"
                            accept="image/*"
                            onChange={(e) =>
                              handleCheckQuestion(index, e, "option1")
                            }
                          />
                          <div>
                            {!question?.option1 ? (
                              <>
                                <div
                                  style={{
                                    width: "100%",
                                    display: "flex",
                                    flexWrap: "wrap",
                                  }}
                                >
                                  <div
                                    style={{
                                      position: "relative",
                                      width: "150px",
                                      margin: "5px",
                                    }}
                                  >
                                    <img
                                      src={question?.option1}
                                      width="100%"
                                      alt="img"
                                    />
                                  </div>
                                </div>
                              </>
                            ) : (
                              <>
                                <div
                                  style={{
                                    width: "100%",
                                    display: "flex",
                                    flexWrap: "wrap",
                                  }}
                                >
                                  <div
                                    style={{
                                      position: "relative",
                                      width: "150px",
                                      margin: "5px",
                                    }}
                                  >
                                    <img
                                      src={question?.option1}
                                      width="100%"
                                      alt="img"
                                    />
                                  </div>
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="dn_input_box">
                        <div className="input_style">
                          <h6>Option B</h6>
                          <input
                            type="file"
                            id="img"
                            name="img"
                            accept="image/*"
                            onChange={(e) =>
                              handleCheckQuestion(index, e, "option2")
                            }
                          />
                          <div>
                            {!question?.option2 ? (
                              <>
                                <div
                                  style={{
                                    width: "100%",
                                    display: "flex",
                                    flexWrap: "wrap",
                                  }}
                                >
                                  <div
                                    style={{
                                      position: "relative",
                                      width: "150px",
                                      margin: "5px",
                                    }}
                                  >
                                    <img
                                      src={question?.option2}
                                      width="100%"
                                      alt="img"
                                    />
                                  </div>
                                </div>
                              </>
                            ) : (
                              <>
                                <div
                                  style={{
                                    width: "100%",
                                    display: "flex",
                                    flexWrap: "wrap",
                                  }}
                                >
                                  <div
                                    style={{
                                      position: "relative",
                                      width: "150px",
                                      margin: "5px",
                                    }}
                                  >
                                    <img
                                      src={question?.option2}
                                      width="100%"
                                      alt="img"
                                    />
                                  </div>
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="dn_input_box mt-3"></div>
                      <div className="dn_input_box mt-3"></div>
                      <div className="dn_input_box">
                        <div className="input_style">
                          <h6>Option C</h6>
                          <input
                            type="file"
                            id="img"
                            name="img"
                            accept="image/*"
                            onChange={(e) =>
                              handleCheckQuestion(index, e, "option3")
                            }
                          />
                          <div>
                            {!question?.option3 ? (
                              <>
                                <div
                                  style={{
                                    width: "100%",
                                    display: "flex",
                                    flexWrap: "wrap",
                                  }}
                                >
                                  <div
                                    style={{
                                      position: "relative",
                                      width: "150px",
                                      margin: "5px",
                                    }}
                                  >
                                    <img
                                      src={question?.option3}
                                      width="100%"
                                      alt="img"
                                    />
                                  </div>
                                </div>
                              </>
                            ) : (
                              <>
                                <div
                                  style={{
                                    width: "100%",
                                    display: "flex",
                                    flexWrap: "wrap",
                                  }}
                                >
                                  <div
                                    style={{
                                      position: "relative",
                                      width: "150px",
                                      margin: "5px",
                                    }}
                                  >
                                    <img
                                      src={question?.option3}
                                      width="100%"
                                      alt="img"
                                    />
                                  </div>
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="dn_input_box">
                        <div className="input_style">
                          <h6>Option D</h6>
                          <input
                            type="file"
                            id="img"
                            name="img"
                            accept="image/*"
                            onChange={(e) =>
                              handleCheckQuestion(index, e, "option4")
                            }
                          />
                          <div>
                            {!question?.option4 ? (
                              <>
                                <div
                                  style={{
                                    width: "100%",
                                    display: "flex",
                                    flexWrap: "wrap",
                                  }}
                                >
                                  <div
                                    style={{
                                      position: "relative",
                                      width: "150px",
                                      margin: "5px",
                                    }}
                                  >
                                    <img
                                      src={question?.option4}
                                      width="100%"
                                      alt="img"
                                    />
                                  </div>
                                </div>
                              </>
                            ) : (
                              <>
                                <div
                                  style={{
                                    width: "100%",
                                    display: "flex",
                                    flexWrap: "wrap",
                                  }}
                                >
                                  <div
                                    style={{
                                      position: "relative",
                                      width: "150px",
                                      margin: "5px",
                                    }}
                                  >
                                    <img
                                      src={question?.option4}
                                      width="100%"
                                      alt="img"
                                    />
                                  </div>
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="dn_input_box">
                        <TextField
                          label=" Option A"
                          type="text"
                          id="text"
                          className="input_style"
                          error={
                            Questionsoptionoption1error !== "" &&
                            QuestionsoptionIndex === index
                              ? true
                              : false
                          }
                          helperText={
                            Questionsoptionoption1error !== "" &&
                            QuestionsoptionIndex === index
                              ? "Required"
                              : ""
                          }
                          value={question?.option1}
                          onChange={(e) =>
                            handleCheckQuestion(index, e, "option1")
                          }
                          autocomplete="off"
                          size="small"
                        />
                      </div>
                      <div className="dn_input_box">
                        <TextField
                          label=" Option B"
                          type="text"
                          id="text"
                          className="input_style"
                          error={
                            Questionsoptionoption2error !== "" &&
                            QuestionsoptionIndex === index
                              ? true
                              : false
                          }
                          helperText={
                            Questionsoptionoption2error !== "" &&
                            QuestionsoptionIndex === index
                              ? "Required"
                              : ""
                          }
                          value={question?.option2}
                          onChange={(e) =>
                            handleCheckQuestion(index, e, "option2")
                          }
                          autocomplete="off"
                          size="small"
                        />
                      </div>
                      <div className="dn_input_box mt-3"></div>
                      <div className="dn_input_box mt-3"></div>
                      <div className="dn_input_box">
                        <TextField
                          label=" Option C"
                          type="text"
                          id="text"
                          className="input_style"
                          error={
                            Questionsoptionoption3error !== "" &&
                            QuestionsoptionIndex === index
                              ? true
                              : false
                          }
                          helperText={
                            Questionsoptionoption3error !== "" &&
                            QuestionsoptionIndex === index
                              ? "Required"
                              : ""
                          }
                          value={question?.option3}
                          onChange={(e) =>
                            handleCheckQuestion(index, e, "option3")
                          }
                          autocomplete="off"
                          size="small"
                        />
                      </div>
                      <div className="dn_input_box">
                        <TextField
                          label=" Option D"
                          type="text"
                          id="text"
                          className="input_style"
                          error={
                            Questionsoptionoption4error !== "" &&
                            QuestionsoptionIndex === index
                              ? true
                              : false
                          }
                          helperText={
                            Questionsoptionoption4error !== "" &&
                            QuestionsoptionIndex === index
                              ? "Required"
                              : ""
                          }
                          value={question?.option4}
                          onChange={(e) =>
                            handleCheckQuestion(index, e, "option4")
                          }
                          autocomplete="off"
                          size="small"
                        />
                      </div>
                    </>
                  )}
                  <div className="dn_input_box mt-3"></div>
                  <div className="dn_input_box mt-3"></div>
                  <div className="dn_input_box">
                    <TextField
                      label="Correct Answer"
                      type="text"
                      id="text"
                      className="input_style"
                      error={
                        correctAnswererror !== "" &&
                        QuestionsoptionIndex === index
                          ? true
                          : false
                      }
                      helperText={
                        correctAnswererror !== "" &&
                        QuestionsoptionIndex === index
                          ? "Required"
                          : ""
                      }
                      value={question?.correctAnswer}
                      onChange={(e) =>
                        handleCheckQuestion(index, e, "correctAnswer")
                      }
                      autocomplete="off"
                      size="small"
                    />
                  </div>
                  {question?.answerExplainationStatus === true && (
                    <>
                      <div className="dn_input_box mt-3"></div>
                      <div className="dn_input_box mt-3"></div>
                      <div className="success_input_box">
                        <TextField
                          label={`Answer Explaination`}
                          style={{
                            width: "98%",
                          }}
                          error={
                            QuestionsoptionbtnPress === true &&
                            question?.answerExplaination === "" &&
                            question?.answerExplainationStatus === true &&
                            QuestionsoptionIndex === index
                              ? true
                              : false
                          }
                          helperText={
                            QuestionsoptionbtnPress === true &&
                            question?.answerExplaination === "" &&
                            question?.answerExplainationStatus === true &&
                            QuestionsoptionIndex === index
                              ? "Required"
                              : ""
                          }
                          value={question?.answerExplaination}
                          onChange={(e) =>
                            handleCheckQuestion(index, e, "answerExplaination")
                          }
                          autocomplete="off"
                          id="outlined-multiline-static"
                          multiline
                          rows={4}
                        />
                      </div>
                      <div className="dn_input_box mt-3"></div>
                    </>
                  )}
                  {question?.qustion_discriptionStatus === true && (
                    <>
                      <div className="dn_input_box mt-3"></div>
                      <div className="dn_input_box mt-3"></div>
                      <div className="success_input_box">
                        <TextField
                          label={`Qustion Discription`}
                          style={{
                            width: "98%",
                          }}
                          error={
                            QuestionsoptionbtnPress === true &&
                            question?.qustion_discription === "" &&
                            question?.qustion_discriptionStatus === true &&
                            QuestionsoptionIndex === index
                              ? true
                              : false
                          }
                          helperText={
                            QuestionsoptionbtnPress === true &&
                            question?.qustion_discription === "" &&
                            question?.qustion_discriptionStatus === true &&
                            QuestionsoptionIndex === index
                              ? "Required"
                              : ""
                          }
                          value={question?.qustion_discription}
                          onChange={(e) =>
                            handleCheckQuestion(index, e, "qustion_discription")
                          }
                          autocomplete="off"
                          id="outlined-multiline-static"
                          multiline
                          rows={4}
                        />
                      </div>
                      <div className="dn_input_box mt-3"></div>
                    </>
                  )}
                  <div className="dn_input_box mt-3"></div>
                  <div className="dn_input_box mt-3"></div>
                  <div className="dn_input_box mt-3"></div>
                  {question?.imageStatus === true && (
                    <>
                      <div className="dn_input_box">
                        <div className="input_style">
                          <h6>Thumbnail</h6>
                          <input
                            type="file"
                            id="img"
                            name="img"
                            accept="image/*"
                            onChange={(e) =>
                              handleCheckQuestion(index, e, "image")
                            }
                          />
                          <div>
                            {!question?.image ? (
                              <>
                                <div
                                  style={{
                                    width: "100%",
                                    display: "flex",
                                    flexWrap: "wrap",
                                  }}
                                >
                                  <div
                                    style={{
                                      position: "relative",
                                      width: "150px",
                                      margin: "5px",
                                    }}
                                  >
                                    <img
                                      src={question?.image}
                                      width="100%"
                                      alt="img"
                                    />
                                  </div>
                                </div>
                              </>
                            ) : (
                              <>
                                <div
                                  style={{
                                    width: "100%",
                                    display: "flex",
                                    flexWrap: "wrap",
                                  }}
                                >
                                  <div
                                    style={{
                                      position: "relative",
                                      width: "150px",
                                      margin: "5px",
                                    }}
                                  >
                                    <img
                                      src={question?.image}
                                      width="100%"
                                      alt="img"
                                    />
                                  </div>
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                  {question?.VideoStatus === true && (
                    <>
                      <div className="dn_input_box">
                        <div className="input_style">
                          <h6>Video</h6>
                          <input
                            type="file"
                            id="videoUpload"
                            name="video"
                            accept="video/*"
                            onChange={(e) =>
                              handleCheckQuestion(index, e, "Video")
                            }
                          />
                          <div>
                            {question?.Video !== "" && (
                              <>
                                <div
                                  style={{
                                    width: "100%",
                                    display: "flex",
                                    marginTop: "2vh",
                                  }}
                                >
                                  <video
                                    ref={videoRef}
                                    width="200"
                                    height="120"
                                    controls
                                    onPlay={handlePlay}
                                    onPause={handlePause}
                                  >
                                    <source
                                      src={question?.Video}
                                      type="video/mp4"
                                    />
                                  </video>
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                  {Questionsoption.length !== 1 && (
                    <div className="button_container">
                      <LoadingButton
                        variant="contained"
                        color="primary"
                        onClick={(e) => removeRowClickQuestion(e, index)}
                      >
                        Remove
                      </LoadingButton>
                    </div>
                  )}
                </>
              ))}
              <div className="button_container2">
                <LoadingButton
                  variant="contained"
                  color="primary"
                  onClick={handleNewRowQuestion}
                >
                  Add More
                </LoadingButton>
              </div>
            </div>
            <div className="button_container3">
              <LoadingButton
                variant="contained"
                color="primary"
                onClick={(e) => handleSavesubmit(e)}
                disabled={btnLoadsave}
              >
                {btnLoadsave ? (
                  <>
                    LOading
                    <LoadingIndicator size={24} />
                  </>
                ) : (
                  "Save"
                )}
              </LoadingButton>
              <LoadingButton
                variant="contained"
                color="primary"
                onClick={(e) => handlesubmit(e)}
                disabled={btnLoading}
              >
                {btnLoading ? (
                  <>
                    LOading
                    <LoadingIndicator size={24} />
                  </>
                ) : (
                  "Update"
                )}
              </LoadingButton>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Update_Test;
