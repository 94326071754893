import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import axios from "axios";

const Baseurl = process.env.REACT_APP_BASE_URL
const initialState = {
    totalTest: [],
    totalAvailableTest: [],
    totalPendingTest: [],
    TestLoading: true,
};

export const testPost = createAsyncThunk(
    "test/testPost",
    async (formData, thunkAPI) => {
        try {
            const config = {
                Headers: {
                    "content-type": "application/json",
                },
            };
            const url = `${Baseurl}/api/v1/testpaper/new`;
            const resp = await axios.post(url, formData, config);
            return resp.data;
        } catch (error) {
            return thunkAPI.rejectWithValue("test not able to upload");
        }
    }
);
export const getAlltestpaper = createAsyncThunk(
    "test/getAlltestpaper",
    async (number, thunkAPI) => {
        try {
            const url = `${Baseurl}/api/v1/testpaper/all`;
            const resp = await axios.get(url);
            return resp.data;
        } catch (error) {
            return error;
        }
    }
);
export const updatetest = createAsyncThunk(
    "test/updatetest",
    async (formData, thunkAPI) => {
        try {
            const config = {
                Headers: {
                    "content-type": "application/json",
                },
            };
            const url = `${Baseurl}/api/v1/testpaper/update-testmanagement/${formData._id}`;
            const resp = await axios.put(url, formData, config);
            return resp.data;
        } catch (error) {
            return thunkAPI.rejectWithValue("auth not able to update");
        }
    }
);
export const valiadteSlugurl = createAsyncThunk(
    "testManagement/valiadteSlugurl",
    async (slugUrl, thunkAPI) => {
        try {
            const url = `${Baseurl}/api/v1/testpaper/testpaper-slugurl/${slugUrl}`;
            const resp = await axios.get(url);
            return resp.data;
        } catch (error) {
            return error;
        }
    }
);
export const valiadteTestCode = createAsyncThunk(
    "testManagement/valiadteTestCode",
    async (TestCode, thunkAPI) => {
        try {
            const url = `${Baseurl}/api/v1/testpaper/testpaper-TestCode/${TestCode}`;
            const resp = await axios.get(url);
            return resp.data;
        } catch (error) {
            return error;
        }
    }
);

export const TestThumbnailUpload = createAsyncThunk(
    "testManagement/TestThumbnailUpload",
    async (formData, thunkAPI) => {
        try {
            const config = {
                Headers: { "Content-Type": "application/json" },
                maxBodyLength: Infinity,
            };
            const url = `${Baseurl}/api/v1/testpaper/uploade-image`;
            const resp = await axios.post(url, formData, config);
            return resp.data;
        } catch (error) {
            return thunkAPI.rejectWithValue("Blog image not able to  upload");
        }
    }
);
export const TestVideoUpload = createAsyncThunk(
    "testManagement/TestVideoUpload",
    async (formData, thunkAPI) => {
        try {
            const config = {
                Headers: { "Content-Type": "multipart/form-data", },
                maxBodyLength: Infinity,
            };
            const url = `${Baseurl}/api/v1/testpaper/upload-video`;
            const resp = await axios.post(url, formData, config);
            return resp.data;
        } catch (error) {
            return thunkAPI.rejectWithValue("Unable to upload video");
        }
    }
);

const testPaperSlice = createSlice({
    name: "testPaper",
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAlltestpaper.pending, (state) => {
                state.TestLoading = true;
            })
            .addCase(getAlltestpaper.fulfilled, (state, action) => {
                if (action.payload.success) {
                    state.totalTest = action.payload.testManagement
                    state.totalAvailableTest = state.totalTest.filter((data) => data.status === true)
                    state.totalPendingTest = state.totalTest.filter((data) => data.status === false)
                }
                state.TestLoading = false;
            })
            .addCase(getAlltestpaper.rejected, (state) => {
                state.TestLoading = true;
            })
            .addCase(testPost.pending, (state) => {
                state.TestLoading = true;
            })
            .addCase(testPost.fulfilled, (state, action) => {
                if (action.payload.success) {
                    state.totalTest = [action.payload.testManagement, ...state.totalTest];
                    state.totalAvailableTest = state.totalTest.filter((data) => data.status === true)
                    state.totalPendingTest = state.totalTest.filter((data) => data.status === false)
                }
                state.TestLoading = false;
            })
            .addCase(testPost.rejected, (state) => {
                state.TestLoading = true;
            })

            .addCase(updatetest.pending, (state) => {
                state.TestLoading = true;
            })
            .addCase(updatetest.fulfilled, (state, action) => {
                if (action.payload.success) {
                    state.totalTest = state.totalTest.filter(
                        (test) => test._id !== action.payload.testManagement._id
                    );
                    state.totalTest = [action.payload.testManagement, ...state.totalTest];
                    state.totalAvailableTest = state.totalTest.filter((data) => data.status === true)
                    state.totalPendingTest = state.totalTest.filter((data) => data.status === false)
                }
                state.TestLoading = false;
            })
            .addCase(updatetest.rejected, (state) => {
                state.TestLoading = true;
            })
    },
});
export const {
} = testPaperSlice.actions;
export default testPaperSlice.reducer;