import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import MenuItem from "@mui/material/MenuItem";
import { TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { PiPaperPlaneRight } from "react-icons/pi";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import CircularProgress from "@mui/material/CircularProgress";
import { Typography, message, Radio } from "antd";
import {
  universityPost,
  valiadteSlugurl,
} from "../../../redux/university/UniversitySlice";
import { read, utils } from "xlsx";

const AddBulkUploade = () => {
  const [btnPress, setbtnPress] = useState(false);
  const [data, setData] = useState("");
  const [slugUrlError, setSlugUrlError] = useState("");
  const [addUniversitySuccess, setaddUniversitySuccess] = useState("");

  const [btnLoading, setbtnLoading] = useState(false);

  const dispatch = useDispatch();

  const handlefile = async (e) => {
    const file = e.target.files[0];
    const f = await file.arrayBuffer();
    const wb = read(f);
    const worksheetname = wb.SheetNames[0];
    const worksheet = wb.Sheets[worksheetname];
    const datas = utils.sheet_to_json(worksheet);
    console.log(datas);
    setData(datas);
  };

  const cancelClick = async (e) => {
    e.preventDefault();
  };

  const handlesubmit = async (e) => {
    e.preventDefault();
    setbtnLoading(true);
    for (let i in data) {
      let count = 0;
      try {
        let myrow = data[i];
        const heigherst_defree = [
          { name: "Non-degree-granting", id: "0" },
          { name: "Certificate degree", id: "1" },
          { name: "Associate degree", id: "2" },
          { name: "Bachelor's degree", id: "3" },
          { name: "Graduate degree", id: "4" },
        ];

        const find_high_degree = heigherst_defree.find(
          (data) => data.id === String(myrow.HIGHDEG)
        );

        let total_UGDS_MEN = 0;

        if (String(myrow.UGDS_MEN) !== "NA") {
          total_UGDS_MEN = Number(myrow.UGDS_MEN) * 100;
        }
        let total_UGDS_WOMEN = 0;

        if (String(myrow.UGDS_WOMEN) !== "NA") {
          total_UGDS_WOMEN = Number(myrow.UGDS_WOMEN) * 100;
        }
        let name = String(myrow.INSTNM);
        let univId = String(myrow.UNITID);
        let slug = name
          .trim()
          .toLowerCase()
          .replace(" ", "-")
          .replace(/[.*+&?^ $@#%^!'/{}()|[\]\\]/g, "-")
          .replace("---", "-")
          .replace("----", "-")
          .replace("--", "-");

        let response = await dispatch(valiadteSlugurl(univId));
        if (response.payload.success === true) {
          setSlugUrlError(`${name} already exist`);
        } else {
          try {
            const formData = {
              universityName: String(myrow.INSTNM),
              UNITID: String(myrow.UNITID),
              OPEID: String(myrow.OPEID),
              slugUrl: slug,
              universityDescription: "",
              universityPhone: "",
              address: String(myrow.ADDR),
              city: String(myrow.CITY),
              state: String(myrow.STABBR),
              zipcode: String(myrow.ZIP),
              ACCREDAGENCY: String(myrow.ACCREDAGENCY),
              universityType: String(myrow.CONTROL_PEPS),
              universityWebsite: String(myrow.INSTURL),
              NPCURL: String(myrow.NPCURL),
              universityEmail: "",
              establishedYear: "",
              graduate: {
                total: String(myrow.UGNONDS),
                male: "",
                female: "",
              },
              Undergraduate: {
                total: String(myrow.UGDS),
                male: String(total_UGDS_MEN),
                female: String(total_UGDS_WOMEN),
                UGDS_WHITE: String(myrow.UGDS_WHITE),
                UGDS_BLACK: String(myrow.UGDS_BLACK),
                UGDS_HISP: String(myrow.UGDS_HISP),
                UGDS_ASIAN: String(myrow.UGDS_ASIAN),
                UGDS_AIAN: String(myrow.UGDS_AIAN),
                UGDS_NHPI: String(myrow.UGDS_NHPI),
                UGDS_2MOR: String(myrow.UGDS_2MOR),
                UGDS_NRA: String(myrow.UGDS_NRA),
                UGDS_UNKN: String(myrow.UGDS_UNKN),
                PPTUG_EF: String(myrow.PPTUG_EF),
              },
              TUITIONFEE_OUT: String(myrow.TUITIONFEE_OUT),
              //Tuition Fees, Books and Other Expenses
              toeflTotalScore: String(89),
              IELTSBandScore: String(6.5),
              PTEOverallScore: String(65),
              duolingoOverallScore: String(105),
              SATOverallScore: String(myrow.SAT_AVG),
              ACTCompositeScore: String(myrow.ACTMTMID),
              //NAv == null
              pastUndergradsStudents: "",
              pastgraduateStudents: "",
              pastMastersStudents: "",
              StudentFacultyRatio: "",
              FreshmanRetentionRate: "",
              FourYearGraduationRate: "",
              ApplicationFeesUndergraduate: "100",
              ApplicationFeesGraduate: "100",
              collegeTotalStudent: "",
              HighestDegreeOffered: find_high_degree.name,
              HighestDegreeOfferedId: String(myrow.HIGHDEG),
              AcademicCalendarType: "",
            };
            const newObject = {};
            for (const key in formData) {
              newObject[key] =
                formData[key] === null ||
                formData[key] === "NULL" ||
                formData[key] === "NA"
                  ? ""
                  : formData[key];
            }
            const adduniversity = await dispatch(universityPost(newObject));
            if (adduniversity.payload.success) {
              setaddUniversitySuccess(`${name} university add successfully`);
            }
          } catch (error) {}
        }
      } catch (error) {}
    }
    setbtnLoading(false);
  };

  const LoadingButton = styled(Button)(({ theme }) => ({
    position: "relative",
  }));

  const LoadingIndicator = styled(CircularProgress)(({ theme }) => ({
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  }));
  return (
    <>
      <div className="main_fragnent_container p-2">
        <div className="profile_container_header">
          <div className="profile_co_header_left">
            <span>Load Bulk University</span>
            <span
              style={{
                visibility: "hidden",
              }}
            >
              Student
            </span>
          </div>
          <div className="profile_co_header_right"></div>
        </div>
        <div className="main_recha_container">
          <div className="inputs_container">
            <div className="dn_input_box">
              <div className="input_style">
                <h6>Select File</h6>
                <input
                  type="file"
                  placeholder="enter name....."
                  onChange={(e) => handlefile(e)}
                />
              </div>
            </div>
            <div className="dn_input_box"></div>
            <p style={{ color: "red" }}>{slugUrlError}</p>
            <p style={{ color: "green" }}>{addUniversitySuccess}</p>
            <div className="button_container">
              <LoadingButton
                variant="contained"
                color="primary"
                onClick={(e) => handlesubmit(e)}
                disabled={btnLoading}
              >
                {btnLoading ? (
                  <>
                    LOading
                    <LoadingIndicator size={24} />
                  </>
                ) : (
                  "Submit"
                )}
              </LoadingButton>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddBulkUploade;
