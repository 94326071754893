import { configureStore } from "@reduxjs/toolkit";
import BlogReducer from "../redux/Blogs/BlogSlice";
import AuthReducer from "../redux/Authentication/AuthSlice";
import notificationsReducer from "../redux/Notifications/Notificationslice";
import testManagementReducer from "../redux/TestManagement/TextManagementSlice";
import studentReducer from "../redux/student/StudentSlice";
import universityReducer from "../redux/university/UniversitySlice";
import programReducer from "../redux/Program/ProgramSlice";
import testPaperReducer from "../redux/testPaper/TestPaperSlice";

export const store = configureStore({
  reducer: {
    auth: AuthReducer,
    blog: BlogReducer,
    notifications: notificationsReducer,
    testManagement: testManagementReducer,
    student: studentReducer,
    university: universityReducer,
    porgrams: programReducer,
    testPaper: testPaperReducer,
  },
});
