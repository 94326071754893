import React, { useEffect, useState } from "react";
import { TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { PiPaperPlaneRight } from "react-icons/pi";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import CircularProgress from "@mui/material/CircularProgress";
import { read, utils } from "xlsx";
import { Typography, message, Radio } from "antd";
import { majorSubjectPost } from "../redux/university/UniversitySlice";

const Add_MajorSubjects = () => {
  const [data, setData] = useState("");
  const [slugUrlError, setSlugUrlError] = useState("");
  const [addUniversitySuccess, setaddUniversitySuccess] = useState("");
  const [btnLoading, setbtnLoading] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();

  const dispatch = useDispatch();

  const handlefile = async (e) => {
    const file = e.target.files[0];
    const f = await file.arrayBuffer();
    const wb = read(f);
    const worksheetname = wb.SheetNames[0];
    const worksheet = wb.Sheets[worksheetname];
    const datas = utils.sheet_to_json(worksheet);
    console.log(datas);
    setData(datas);
  };

  const handlesubmit = async (e) => {
    e.preventDefault();
    setbtnLoading(true);
    messageApi.open({
      type: "info",
      content: "Your Request has been sent to server wait for responce",
    });
    for (let i in data) {
      try {
        let myrow = data[i];
        try {
          const formData = {
            code: String(myrow.Code),
            name: String(myrow.Title),
          };
          const add_major_subjects = await dispatch(majorSubjectPost(formData));
          if (add_major_subjects.payload.success) {
            messageApi.open({
              type: "success",
              content: `${myrow.Code} - ${myrow.Title} Major subject all created successfully`,
            });
          }
        } catch (error) {
          setbtnLoading(false);
        }
      } catch (error) {
        setbtnLoading(false);
      }
    }
    setbtnLoading(false);
    messageApi.open({
      type: "success",
      content: "Major subject all created successfully",
    });
  };

  const LoadingButton = styled(Button)(({ theme }) => ({
    position: "relative",
  }));

  const LoadingIndicator = styled(CircularProgress)(({ theme }) => ({
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  }));

  return (
    <>
      {contextHolder}
      <div className="main_fragnent_container p-2">
        <div className="profile_container_header">
          <div className="profile_co_header_left">
            <span>Add University</span>
            <span
              style={{
                visibility: "hidden",
              }}
            >
              Student
            </span>
          </div>
          <div className="profile_co_header_right"></div>
        </div>
        <div className="main_recha_container">
          <div className="inputs_container">
            <div className="dn_input_box">
              <TextField
                label="Name"
                type="file"
                id="text"
                className="input_style"
                // error={universityError !== "" ? true : false}
                // helperText={universityError}
                // value={universityName}
                onChange={(e) => handlefile(e)}
                autocomplete="off"
                size="small"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
            <div className="dn_input_box"></div>
            {data.length > 0 && (
              <div className="button_container">
                <LoadingButton
                  variant="contained"
                  color="primary"
                  onClick={(e) => handlesubmit(e)}
                  disabled={btnLoading}
                >
                  {btnLoading ? (
                    <>
                      LOading
                      <LoadingIndicator size={24} />
                    </>
                  ) : (
                    "Submit"
                  )}
                </LoadingButton>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Add_MajorSubjects;
