import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FaPrint } from "react-icons/fa6";
import { styled } from "@mui/material/styles";
import { Box, TextField } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TablePagination from "@mui/material/TablePagination";
import { Link, useNavigate, useParams } from "react-router-dom";
import moment from "moment/moment";
import { CSVLink } from "react-csv";
import {
  DownOutlined,
  CaretDownOutlined,
  PlusOutlined,
  FileExcelOutlined,
} from "@ant-design/icons";
import { DatePicker, Input, Select, Button, Flex, Modal, message } from "antd";
import axios from "axios";
import { FaEdit, FaBell } from "react-icons/fa";
import { MdEdit } from "react-icons/md";
const { RangePicker } = DatePicker;

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    // backgroundColor: theme.palette.common.black,
    backgroundColor: "#006fa9",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&td, &th": {
    border: 0,
  },
}));

const GradeReport = () => {
  return (
    <>
      <div className="main_fragnent_container p-2">
        <div className="profile_container_header">
          <div className="profile_co_header_left">
            <span>Grades Report</span>
            <span
              style={{
                visibility: "hidden",
              }}
            >
              Student
            </span>
          </div>
          <div className="profile_co_header_right"></div>
        </div>
      </div>
    </>
  );
};

export default GradeReport;
