import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import MenuItem from "@mui/material/MenuItem";
import { TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { PiPaperPlaneRight } from "react-icons/pi";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import CircularProgress from "@mui/material/CircularProgress";
import { Typography, message, Radio } from "antd";
import {
  universityPost,
  updateuniversity,
  valiadteSlugurl,
} from "../../../redux/university/UniversitySlice";
import axios from "axios";

const Update_University = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const [slugUrl, setSlugUrl] = useState("");
  const [checkSlugUrl, setCheckSlugUrl] = useState("");
  const [slugUrlError, setSlugUrlError] = useState("");
  const [universityName, setuniversityName] = useState("");
  const [universityError, setuniversityError] = useState("");
  const [universityDesc, setuniversityDesc] = useState("");
  const [universityDescError, setUniversityDescError] = useState("");
  const [universityPhn, setUniversityPhn] = useState("");
  const [universityPhnError, setUniversityPhnError] = useState("");
  const [universityAddress, setUniversityAddress] = useState("");
  const [universityAddressError, setUniversityAddressError] = useState("");
  const [universityCity, setUniversityCity] = useState("");
  const [universityCityError, setUniversityCityError] = useState("");
  const [universityType, setUniversityType] = useState("Public");
  const [universityTypeError, setUniversityTypeError] = useState("");
  const [website, setWebsite] = useState("");
  const [websiteError, setWebsiteError] = useState("");
  const [YearEstablished, setYearEstablished] = useState("");
  const [YearEstablishedError, setYearEstablishedError] = useState("");
  const [boyIngrad, setBoyInGrad] = useState("");
  const [boyIngradError, setBoyInGradError] = useState("");
  const [girlIngrad, setGirlInGrad] = useState("");
  const [girlIngradError, setGirlInGradError] = useState("");
  const [boyInUndergrad, setBoyUndergrad] = useState("");
  const [boyInUndergradError, setBoyInUndergradError] = useState("");
  const [girlInUndergrad, setGirlInUndergrad] = useState("");
  const [girlInUndergradError, setGirlInUndergradError] = useState("");
  const [toeflTotalScore, setToeflTotalScore] = useState("");
  const [toeflTotalScoreError, setToeflTotalScoreError] = useState("");
  const [IELTSBandScore, setIELTSBandScore] = useState("");
  const [IELTSBandScoreError, setIELTSBandScoreError] = useState("");
  const [PTEOverallScore, setPTEOverallScore] = useState("");
  const [PTEOverallScoreError, setPTEOverallScoreError] = useState("");
  const [duolingoOverallScore, setDuolingoOverallScore] = useState("");
  const [duolingoOverallScoreError, setDuolingoOverallScoreError] =
    useState("");

  const [SATOverallScore, setSATOverallScore] = useState("");
  const [SATOverallScoreError, setSATOverallScoreError] = useState("");

  const [ACTCompositeScore, setACTCompositeScore] = useState("");
  const [ACTCompositeScoreError, setACTCompositeScoreError] = useState("");

  useState("");

  const [btnPress, setbtnPress] = useState(false);
  const [btnLoading, setbtnLoading] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [universityId, setUniversityId] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      const url = `${process.env.REACT_APP_BASE_URL}/api/v1/university/single-university/${params._id}`;
      const fetchadUniversity = await axios.get(url);
      if (fetchadUniversity.data.success === true) {
        const foundUniversity = fetchadUniversity.data.university;
        setUniversityId(foundUniversity._id);
        setuniversityName(foundUniversity.universityName);
        // setLocation(foundUniversity.Location);
        setCheckSlugUrl(foundUniversity.slugUrl);
        setYearEstablished(foundUniversity.YearEstablished);
        setuniversityDesc(foundUniversity.universityDesc);
        setUniversityType(foundUniversity.universityType);
        setUniversityPhn(foundUniversity.universityPhn);
        setUniversityAddress(foundUniversity.universityAddress);
        setUniversityCity(foundUniversity.universityCity);
        setGirlInGrad(foundUniversity.UniversityType);
        setBoyInGrad(foundUniversity.UniversityType);
        setBoyUndergrad(foundUniversity.UniversityType);
        setGirlInUndergrad(foundUniversity.UniversityType);
        setToeflTotalScore(foundUniversity.toeflTotalScore);
        setIELTSBandScore(foundUniversity.IELTSBandScore);
        setPTEOverallScore(foundUniversity.PTEOverallScore);
        setDuolingoOverallScore(foundUniversity.UniversityType);
        setSATOverallScore(foundUniversity.UniversityType);
        setACTCompositeScore(foundUniversity.UniversityType);
        setWebsite(foundUniversity.website);
      }
    };
    fetchData();
  }, [params._id]);

  useEffect(() => {
    if (btnPress === true) {
      if (universityName === "") {
        setuniversityError("please enter subTitle");
      } else {
        setuniversityError("");
      }
      if (universityDesc === "") {
        setUniversityDescError("please enter  Description");
      } else {
        setUniversityDescError("");
      }
      if (universityPhn === "") {
        setUniversityPhnError("please enter  Phone");
      } else {
        setUniversityPhnError("");
      }
      if (universityAddress === "") {
        setUniversityAddressError("please enter  Address");
      } else {
        setUniversityAddressError("");
      }
      if (universityCity === "") {
        setUniversityCityError("please enter  City");
      } else {
        setUniversityCityError("");
      }
      if (universityType === "") {
        setUniversityTypeError("please enter  Type");
      } else {
        setUniversityTypeError("");
      }
      if (website === "") {
        setWebsiteError("please enter  Website");
      } else {
        setWebsiteError("");
      }
      if (YearEstablished === "") {
        setYearEstablishedError("please enter  Established Year");
      } else {
        setYearEstablishedError("");
      }

      if (boyIngrad === "") {
        setBoyInGradError("please enter Male in Graduation");
      } else {
        setBoyInGradError("");
      }
      if (girlIngrad === "") {
        setGirlInGradError("please enter Female in Graduation");
      } else {
        setGirlInGradError("");
      }
      if (boyInUndergrad === "") {
        setBoyInUndergradError("please enter Male in Under-Graduation");
      } else {
        setBoyInUndergradError("");
      }
      if (girlInUndergrad === "") {
        setGirlInUndergradError("please enter Female in Under-Graduation");
      } else {
        setGirlInUndergradError("");
      }

      if (toeflTotalScore === "") {
        setToeflTotalScoreError("please enter toeflTotalScore");
      } else {
        setToeflTotalScoreError("");
      }
      if (IELTSBandScore === "") {
        setIELTSBandScoreError("please enter IELTSBandScore");
      } else {
        setIELTSBandScoreError("");
      }
      if (PTEOverallScore === "") {
        setPTEOverallScoreError("please enter PTEOverallScore");
      } else {
        setPTEOverallScoreError("");
      }
      if (duolingoOverallScore === "") {
        setDuolingoOverallScoreError("please enter duolingoOverallScore");
      } else {
        setDuolingoOverallScoreError("");
      }
      if (SATOverallScore === "") {
        setSATOverallScoreError("please enter SATOverallScore");
      } else {
        setSATOverallScoreError("");
      }
      if (ACTCompositeScore === "") {
        setACTCompositeScoreError("please enter ACTCompositeScore");
      } else {
        setACTCompositeScoreError("");
      }
    }
  }, [
    btnPress,
    universityName,
    universityDesc,
    universityPhn,
    universityAddress,
    universityCity,
    universityType,
    website,
    YearEstablished,
    boyIngrad,
    girlIngrad,
    boyInUndergrad,
    girlInUndergrad,
    toeflTotalScore,
    IELTSBandScore,
    PTEOverallScore,
    duolingoOverallScore,
    SATOverallScore,
    ACTCompositeScore,
  ]);

  const handleTitle = async (e) => {
    const value = e.target.value.trim();
    setSlugUrl(value);
    setuniversityName(value);
    let slug = value
      .trim()
      .toLowerCase()
      .replace(" ", "-")
      .replace(/[.*+&?^ $@#%^!'/{}()|[\]\\]/g, "-")
      .replace("---", "-")
      .replace("----", "-")
      .replace("--", "-");
    setSlugUrl(slug);
    if (slug !== "") {
      let response = await dispatch(valiadteSlugurl(slug));
      if (response.payload.success) {
        setSlugUrlError("name already exist");
      } else {
        setCheckSlugUrl(slug);
        setuniversityName(value);
        setSlugUrlError("");
      }
    }
  };

  const handlesubmit = async (e) => {
    e.preventDefault();
    setbtnLoading(true);
    if (universityName === "") {
      setuniversityError("please enter subTitle");
    } else {
      setuniversityError("");
    }
    if (universityDesc === "") {
      setUniversityDescError("please enter  Description");
    } else {
      setUniversityDescError("");
    }
    if (universityPhn === "") {
      setUniversityPhnError("please enter  Phone");
    } else {
      setUniversityPhnError("");
    }
    if (universityAddress === "") {
      setUniversityAddressError("please enter  Address");
    } else {
      setUniversityAddressError("");
    }
    if (universityCity === "") {
      setUniversityCityError("please enter  City");
    } else {
      setUniversityCityError("");
    }
    if (universityType === "") {
      setUniversityTypeError("please enter  Type");
    } else {
      setUniversityTypeError("");
    }
    if (website === "") {
      setWebsiteError("please enter  Website");
    } else {
      setWebsiteError("");
    }
    if (YearEstablished === "") {
      setYearEstablishedError("please enter  Established Year");
    } else {
      setYearEstablishedError("");
    }

    if (boyIngrad === "") {
      setBoyInGradError("please enter Male in Graduation");
    } else {
      setBoyInGradError("");
    }
    if (girlIngrad === "") {
      setGirlInGradError("please enter Female in Graduation");
    } else {
      setGirlInGradError("");
    }
    if (boyInUndergrad === "") {
      setBoyInUndergradError("please enter Male in Under-Graduation");
    } else {
      setBoyInUndergradError("");
    }
    if (girlInUndergrad === "") {
      setGirlInUndergradError("please enter Female in Under-Graduation");
    } else {
      setGirlInUndergradError("");
    }

    if (toeflTotalScore === "") {
      setToeflTotalScoreError("please enter toeflTotalScore");
    } else {
      setToeflTotalScoreError("");
    }
    if (IELTSBandScore === "") {
      setIELTSBandScoreError("please enter IELTSBandScore");
    } else {
      setIELTSBandScoreError("");
    }
    if (PTEOverallScore === "") {
      setPTEOverallScoreError("please enter PTEOverallScore");
    } else {
      setPTEOverallScoreError("");
    }
    if (duolingoOverallScore === "") {
      setDuolingoOverallScoreError("please enter duolingoOverallScore");
    } else {
      setDuolingoOverallScoreError("");
    }
    if (SATOverallScore === "") {
      setSATOverallScoreError("please enter SATOverallScore");
    } else {
      setSATOverallScoreError("");
    }
    if (ACTCompositeScore === "") {
      setACTCompositeScoreError("please enter ACTCompositeScore");
    } else {
      setACTCompositeScoreError("");
    }

    if (
      universityName !== "" &&
      universityDesc !== "" &&
      universityPhn !== "" &&
      universityAddress !== "" &&
      universityCity !== "" &&
      universityType !== "" &&
      website !== "" &&
      YearEstablished !== "" &&
      boyIngrad !== "" &&
      girlIngrad !== "" &&
      boyInUndergrad !== "" &&
      girlInUndergrad !== "" &&
      toeflTotalScore !== "" &&
      IELTSBandScore !== "" &&
      PTEOverallScore !== "" &&
      duolingoOverallScore !== "" &&
      SATOverallScore !== "" &&
      ACTCompositeScore !== ""
    ) {
      // messageApi.open({
      //   type: "info",
      //   content: "Your Request has been sent to server wait for responce",
      // });
      const totalgrad = Number(boyIngrad) + Number(girlIngrad);
      const totalunder = Number(boyInUndergrad) + Number(girlInUndergrad);
      const grad = {
        total: String(totalgrad),
        male: boyIngrad,
        female: girlIngrad,
      };
      const Undergrad = {
        total: String(totalunder),
        male: boyInUndergrad,
        female: girlInUndergrad,
      };
      const formData = {
        universityName: universityName,
        slugUrl: checkSlugUrl,
        universityDescription: universityDesc,
        universityPhone: universityPhn,
        address: universityAddress,
        city: universityCity,
        universityType: universityType,
        universityWebsite: website,
        establishedYear: YearEstablished,
        toeflTotalScore: toeflTotalScore,
        IELTSBandScore: IELTSBandScore,
        PTEOverallScore: PTEOverallScore,
        duolingoOverallScore: duolingoOverallScore,
        SATOverallScore: SATOverallScore,
        ACTCompositeScore: ACTCompositeScore,
        graduate: grad,
        Undergraduate: Undergrad,
        _id: universityId,
      };
      const blog = await dispatch(updateuniversity(formData));
      if (blog.payload.success) {
        messageApi.open({
          type: "success",
          content: "university Updated successfully",
        });

        setbtnPress(false);
      }
      setbtnLoading(false);
    } else {
      setbtnPress(true);
      setbtnLoading(false);
    }
  };

  const LoadingButton = styled(Button)(({ theme }) => ({
    position: "relative",
  }));

  const LoadingIndicator = styled(CircularProgress)(({ theme }) => ({
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  }));
  return (
    <>
      {contextHolder}
      <div className="main_fragnent_container p-2">
        <div className="profile_container_header">
          <div className="profile_co_header_left">
            <span>Edit University</span>
            <span
              style={{
                visibility: "hidden",
              }}
            >
              Student
            </span>
          </div>
          <div className="profile_co_header_right"></div>
        </div>
        <div className="main_recha_container">
          <div className="inputs_container">
            <div className="dn_input_box">
              <TextField
                label="Name"
                type="text"
                id="text"
                className="input_style"
                error={universityError !== "" ? true : false}
                helperText={universityError}
                value={universityName}
                onChange={(e) => handleTitle(e)}
                autocomplete="off"
                size="small"
              />
            </div>
            <div className="dn_input_box">
              <TextField
                label="City"
                type="text"
                id="text"
                className="input_style"
                error={universityCityError !== "" ? true : false}
                helperText={universityCityError}
                value={universityCity}
                onChange={(e) => {
                  setUniversityCity(e.target.value);
                }}
                autocomplete="off"
                size="small"
              />
            </div>
            <div className="dn_input_box">
              <div className="input_style">
                <h6>Type</h6>
                <Radio.Group defaultValue={true}>
                  <Radio
                    value={true}
                    checked={universityType === "Public"}
                    onChange={() => {
                      setUniversityType("Public");
                    }}
                  >
                    Public
                  </Radio>
                  <Radio
                    value={false}
                    checked={universityType === "Private"}
                    onChange={() => {
                      setUniversityType("Private");
                    }}
                  >
                    Private
                  </Radio>
                </Radio.Group>
              </div>
            </div>
            <div className="dn_input_box">
              <TextField
                label="Establishment"
                type="text"
                id="text"
                className="input_style"
                error={YearEstablishedError !== "" ? true : false}
                helperText={YearEstablishedError}
                value={YearEstablished}
                onChange={(e) => {
                  setYearEstablished(e.target.value.replace(/\D/g, ""));
                }}
                autocomplete="off"
                size="small"
                inputProps={{
                  minLength: 4,
                  maxLength: 4,
                }}
              />
            </div>
            <div className="dn_input_box">
              <TextField
                label="Phone"
                type="text"
                id="text"
                className="input_style"
                error={universityPhnError !== "" ? true : false}
                helperText={universityPhnError}
                value={universityPhn}
                onChange={(e) => {
                  setUniversityPhn(e.target.value.replace(/\D/g, ""));
                }}
                autocomplete="off"
                size="small"
                inputProps={{
                  minLength: 10,
                  maxLength: 10,
                }}
              />
            </div>
            <div className="dn_input_box">
              <TextField
                label="website"
                type="text"
                id="text"
                className="input_style"
                error={websiteError !== "" ? true : false}
                helperText={websiteError}
                value={website}
                onChange={(e) => {
                  setWebsite(e.target.value);
                }}
                autocomplete="off"
                size="small"
              />
            </div>
            <div className="dn_input_box">
              <TextField
                label="Address"
                className="input_style"
                error={universityAddressError !== "" ? true : false}
                helperText={universityAddressError}
                value={universityAddress}
                onChange={(e) => setUniversityAddress(e.target.value)}
                autocomplete="off"
                id="outlined-multiline-static"
                multiline
                rows={4}
              />
            </div>
            <div className="dn_input_box">
              <TextField
                label="Description"
                className="input_style"
                error={universityDescError !== "" ? true : false}
                helperText={universityDescError}
                value={universityDesc}
                onChange={(e) => setuniversityDesc(e.target.value)}
                autocomplete="off"
                id="outlined-multiline-static"
                multiline
                rows={4}
              />
            </div>
            <div className="main_heading_side">
              <span>Graduation Students</span>
            </div>
            <div className="dn_input_box">
              <TextField
                label="Total Male Student"
                type="text"
                id="text"
                className="input_style"
                error={boyIngradError !== "" ? true : false}
                helperText={boyIngradError}
                value={boyIngrad}
                onChange={(e) => {
                  setBoyInGrad(e.target.value.replace(/\D/g, ""));
                }}
                autocomplete="off"
                size="small"
              />
            </div>
            <div className="dn_input_box">
              <TextField
                label="Total Female Student"
                type="text"
                id="text"
                className="input_style"
                error={girlIngradError !== "" ? true : false}
                helperText={girlIngradError}
                value={girlIngrad}
                onChange={(e) => {
                  setGirlInGrad(e.target.value.replace(/\D/g, ""));
                }}
                autocomplete="off"
                size="small"
              />
            </div>
            <div className="main_heading_side">
              <span>Under-Graduation Students</span>
            </div>
            <div className="dn_input_box">
              <TextField
                label="Total Male Student"
                type="text"
                id="text"
                className="input_style"
                error={boyInUndergradError !== "" ? true : false}
                helperText={boyInUndergradError}
                value={boyInUndergrad}
                onChange={(e) => {
                  setBoyUndergrad(e.target.value.replace(/\D/g, ""));
                }}
                autocomplete="off"
                size="small"
              />
            </div>
            <div className="dn_input_box">
              <TextField
                label="Total Female Student"
                type="text"
                id="text"
                className="input_style"
                error={girlInUndergradError !== "" ? true : false}
                helperText={girlInUndergradError}
                value={girlInUndergrad}
                onChange={(e) => {
                  setGirlInUndergrad(e.target.value.replace(/\D/g, ""));
                }}
                autocomplete="off"
                size="small"
              />
            </div>
            <div className="main_heading_side">
              <span>Graduation Level Exams</span>
            </div>
            <div className="dn_input_box">
              <TextField
                label="Enter TOEFL Total Score"
                type="text"
                id="text"
                className="input_style"
                error={toeflTotalScoreError !== "" ? true : false}
                helperText={toeflTotalScoreError}
                value={toeflTotalScore}
                onChange={(e) => {
                  setToeflTotalScore(e.target.value.replace(/\D/g, ""));
                }}
                autocomplete="off"
                size="small"
              />
            </div>
            <div className="dn_input_box">
              <TextField
                label="Enter IELTS BandScore Score"
                type="text"
                id="text"
                className="input_style"
                error={IELTSBandScoreError !== "" ? true : false}
                helperText={IELTSBandScoreError}
                value={IELTSBandScore}
                onChange={(e) => {
                  setIELTSBandScore(e.target.value.replace(/\D/g, ""));
                }}
                autocomplete="off"
                size="small"
              />
            </div>
            <div className="dn_input_box">
              <TextField
                label="Enter PTE Overall Score"
                type="text"
                id="text"
                className="input_style"
                error={PTEOverallScoreError !== "" ? true : false}
                helperText={PTEOverallScoreError}
                value={PTEOverallScore}
                onChange={(e) => {
                  setPTEOverallScore(e.target.value.replace(/\D/g, ""));
                }}
                autocomplete="off"
                size="small"
              />
            </div>
            <div className="dn_input_box">
              <TextField
                label="Enter Duolingo Overall Score"
                type="text"
                id="text"
                className="input_style"
                error={duolingoOverallScoreError !== "" ? true : false}
                helperText={duolingoOverallScoreError}
                value={duolingoOverallScore}
                onChange={(e) => {
                  setDuolingoOverallScore(e.target.value.replace(/\D/g, ""));
                }}
                autocomplete="off"
                size="small"
              />
            </div>
            <div className="main_heading_side">
              <span>Under-Graduation Level Exams</span>
            </div>
            <div className="dn_input_box">
              <TextField
                label="Enter SAT Overall Score"
                type="text"
                id="text"
                className="input_style"
                error={SATOverallScoreError !== "" ? true : false}
                helperText={SATOverallScoreError}
                value={SATOverallScore}
                onChange={(e) => {
                  setSATOverallScore(e.target.value.replace(/\D/g, ""));
                }}
                autocomplete="off"
                size="small"
              />
            </div>
            <div className="dn_input_box">
              <TextField
                label="Enter ACT Composite Score"
                type="text"
                id="text"
                className="input_style"
                error={ACTCompositeScoreError !== "" ? true : false}
                helperText={ACTCompositeScoreError}
                value={ACTCompositeScore}
                onChange={(e) => {
                  setACTCompositeScore(e.target.value.replace(/\D/g, ""));
                }}
                autocomplete="off"
                size="small"
              />
            </div>
            <div className="button_container">
              <LoadingButton
                variant="contained"
                color="primary"
                onClick={(e) => handlesubmit(e)}
                disabled={btnLoading}
              >
                {btnLoading ? (
                  <>
                    LOading
                    <LoadingIndicator size={24} />
                  </>
                ) : (
                  "Update"
                )}
              </LoadingButton>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Update_University;
