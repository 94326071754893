import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { json } from "react-router-dom";
import axios from "axios";
import { act } from "react-dom/test-utils";
const Baseurl = process.env.REACT_APP_BASE_URL
const initialState = {
    totalStudent: [],
    StudentLoading: true,
    allRegisteredStudents: [],
    allRegisteredStudentsLoading: false,
    allpaymentStudents: [],
    allpaymentStudentsLoading: false,
};

export const getAllStudent = createAsyncThunk(
    "student/getAllStudent",
    async (number, thunkAPI) => {
        try {
            const url = `${Baseurl}/api/v1/testmanagement/all`;
            const resp = await axios.get(url);
            return resp.data;
        } catch (error) {
            return error;
        }
    }
);
export const getAllRegisteredStudent = createAsyncThunk(
    "student/getAllRegisteredStudent",
    async (number, thunkAPI) => {
        try {
            const url = `${Baseurl}/api/v1/student/all`;
            const resp = await axios.get(url);
            return resp.data;
        } catch (error) {
            return error;
        }
    }
);
export const getAllPaymentStudent = createAsyncThunk(
    "student/getAllPaymentStudent",
    async (number, thunkAPI) => {
        try {
            const url = `${Baseurl}/api/v1/student/all-paid-students`;
            const resp = await axios.get(url);
            return resp.data;
        } catch (error) {
            return error;
        }
    }
);

const studentSlice = createSlice({
    name: "student",
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAllStudent.pending, (state) => {
                state.StudentLoading = true;
            })
            .addCase(getAllStudent.fulfilled, (state, action) => {
                if (action.payload.success) {
                    state.totalStudent = action.payload.testManagement
                }
                state.StudentLoading = false;
            })
            .addCase(getAllStudent.rejected, (state) => {
                state.StudentLoading = true;
            })
            .addCase(getAllPaymentStudent.pending, (state) => {
                state.allpaymentStudentsLoading = true;
            })
            .addCase(getAllPaymentStudent.fulfilled, (state, action) => {
                if (action.payload.success) {
                    state.allpaymentStudents = action.payload.students
                }
                state.allpaymentStudentsLoading = false;
            })
            .addCase(getAllPaymentStudent.rejected, (state) => {
                state.allpaymentStudentsLoading = true;
            })
            .addCase(getAllRegisteredStudent.pending, (state) => {
                state.allRegisteredStudentsLoading = true;
            })
            .addCase(getAllRegisteredStudent.fulfilled, (state, action) => {
                if (action.payload.success) {
                    state.allRegisteredStudents = action.payload.students
                }
                state.allRegisteredStudentsLoading = false;
            })
            .addCase(getAllRegisteredStudent.rejected, (state) => {
                state.allRegisteredStudentsLoading = true;
            })
    },
});


export const {
} = studentSlice.actions;
export default studentSlice.reducer;